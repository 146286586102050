import { action, makeObservable, observable } from "mobx";
import ApplicationInsightsProvider from "providers/ApplicationInsightsProvider";
import BaseService from "../services/BaseService";
import { isUndefinedType } from "../utils/TypeGuards";
import { AuthStore } from "./AuthStore";

let _acxRootStoreInstance: RootStoreImpl;

export type Constructor<T extends any = any, A extends any = any> = new (
    ...args: A[]
) => T;

export interface IRootStore {
    getStore<TStore>(store: Constructor<TStore>): TStore;

    setActiveLocation(location: string): void;

    activeLocation: ActiveLocation;
    appInsightsProvider: ApplicationInsightsProvider;
}

export enum ActiveDomain {
    Conversations = 1,
    Evaluation,
}

interface ActiveLocation {
    location: string;
    domain?: ActiveDomain;
}

class RootStoreImpl implements IRootStore {
    #storeMap: Map<
        any,
        (self: ThisParameterType<RootStoreImpl>) => InstanceType<Constructor>
    > = new Map();
    private readonly authHandler: () => Promise<void>;
    public appInsightsProvider = new ApplicationInsightsProvider();

    @observable
    activeLocation: ActiveLocation = {} as any;

    constructor() {
        makeObservable(this);

        if (_acxRootStoreInstance === undefined) {
            this.registerStore(AuthStore);
            this.authHandler =
                this.getStore(AuthStore).handleAuthorizationFailure;
        }
    }

    @action
    setActiveLocation = (location: string) => {
        this.activeLocation = {
            location: location,
            domain: this.resolveCurrentDomainFromLocation(location),
        };
    };

    registerStore(storeClass: Constructor, lazyLoad: boolean = true) {
        if (!this.#storeMap.has(storeClass)) {
            let valueLambda = (self: ThisParameterType<RootStoreImpl>) => {
                const store = new storeClass(self);

                // if (
                //     storeClass instanceof AuthStore ||
                //     store instanceof AuthStore
                // ) {
                //     console.log("Auth store newed up.");
                // }

                for (const property in store) {
                    if (store[property] instanceof BaseService) {
                        (store[property] as BaseService).authFailureHandler =
                            this.authHandler;
                    }
                }

                return store;
            };

            if (!lazyLoad) {
                const instantiatedStore = valueLambda(this);
                valueLambda = (self: ThisParameterType<RootStoreImpl>) =>
                    instantiatedStore;
            }

            this.#storeMap.set(storeClass, valueLambda);
        }
    }

    getStore<TStore>(store: Constructor<TStore>): TStore {
        const storeFactory = this.#storeMap.get(store);

        if (isUndefinedType(storeFactory)) {
            throw new Error(`${store} not registered in RootStore`);
        }

        const instantiatedStore = storeFactory(this);

        this.#storeMap.set(
            store,
            (self: ThisParameterType<RootStoreImpl>) => instantiatedStore,
        );

        return instantiatedStore;
    }

    /**
     *
     * @param location The current pathname. Should be in the form `/path/to/thing`
     */
    private resolveCurrentDomainFromLocation(location: string) {
        const leadingSlashRemoved = location.substring(1);
        const individualPathParts = leadingSlashRemoved.split("/");
        if (individualPathParts.length === 0) return;

        const [appOrActiveDomain, appActiveDomain] = individualPathParts;

        let domainString;
        const isAppPath = appOrActiveDomain === "app";
        if (isAppPath) {
            domainString = appActiveDomain;
        } else {
            domainString = appOrActiveDomain;
        }

        switch (domainString) {
            case "conversations":
                return ActiveDomain.Conversations;
            case "evaluation":
                return ActiveDomain.Evaluation;
            default:
                return undefined;
        }
    }
}

// const _acxRootStoreInstance = new RootStoreImpl();
export const newRootStore = () => {
    if (_acxRootStoreInstance !== undefined) {
        return _acxRootStoreInstance;
    }
    _acxRootStoreInstance = new RootStoreImpl();
    return _acxRootStoreInstance;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default () => _acxRootStoreInstance as RootStoreImpl;

// export default RootStoreImpl;

export const AcxStoreFactory = (lazyLoad: boolean) => {
    return <T>(store: Constructor<T>) => {
        if (!isUndefinedType(store)) {
            newRootStore().registerStore(store, lazyLoad);
            // _acxRootStoreInstance.registerStore(store,lazyLoad);
        }
        return store;
    };
};

export const AcxStore = <T>(store: Constructor<T> | any) => {
    if (!isUndefinedType(store)) {
        newRootStore().registerStore(store, true);
        // _acxRootStoreInstance.registerStore(store, true);
    }
    return store;
};
