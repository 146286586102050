import { AddSharp, ArrowBack, ArrowForwardIosSharp } from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    Grid,
    Theme,
    Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxButton from "components/UI/AcxButton";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import ApplicationPermission from "models/Permission/ApplicationPermission";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "shared/hooks/useForm";
import { AuthStore } from "stores/AuthStore";
import useStyles from "Styles/Styles";
import theme from "Theme/AppTheme";
import { useStore } from "utils/useStore";
import useOrganization, {
    DB_CONNECTION_STATUS,
} from "../hooks/useOrganization";
import { Organization, Saml2BindingType } from "../types/Organization.type";
import IntegrationSettings from "./OrganizationDetails/IntegrationConfiguration/IntegrationSettings";
import MetadataConfigurationWrapper from "./OrganizationDetails/MetadataFileConfiguration/MetadataConfigurationWrapper";
import OrganizationDetails from "./OrganizationDetails/OrganizationDetails";
import SAMLSettings from "./OrganizationDetails/SAMLSettings";
import ServiceSetup from "./OrganizationDetails/ServiceSetup";
import { AcxAgentService } from "services/AcxAgentService";
import AcxDateRangeInput from "components/UI/Calendar/DateRange/AcxDateRangeInput";
import { DatePickerComponentStore } from "stores/ComponentStores/DatePickerComponentStore";

function downloadFromBlob(blob: Blob, name?: string) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    if (name) a.download = name;
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

const createContainerName = (name: string) => {
    return name
        .replace(/[^\w\s-]/g, "")
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/-+/g, "-")
        .substring(0, 63);
};

const defaultValues: Organization = {
    name: "",
    databaseConnectionString: "",
    qbAppId: "",
    blobContainer: "",
    pgpKeyName: "",
    dataProcessingOptions: [],
    isActive: true,
    usesCoreDatabase: true,
    enhancedEvalStatus: false,
    serviceSetup: [],
};

const styles = (theme: Theme) => {
    return createStyles({
        pageContainer: {
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: 20,
            paddingBottom: 20,
        },
        OrgDetailButtons: {
            marginLeft: "auto",
            display: "flex",
            flexDirection: "row",
            gap: "10px",
        },
        BackIcon: {
            fontSize: "20px",
            cursor: "pointer",
        },
        EditPermissionHeader: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
            alignItems: "center",
            gap: "10px",
            marginTop: "8px",
            marginBottom: "8px",
        },
        SavePermissionsButton: {
            marginLeft: "auto",
        },
        EditPermissionBody: {
            width: "100%",
            background: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
        },
        EditPermissionTableHeader: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            backgroundColor: theme.palette.blue.main,
        },
        EditPermissionTargets: {
            display: "flex",
            marginLeft: "auto",
            height: "100%",
        },
        PermissionsTitle: {
            flex: "1 1 0px",
            margin: "auto",
            textAlign: "center",
            color: theme.palette.white.main,
            borderRight: "1px solid white",
        },
        EditPermissionTarget: {
            dispaly: "flex",
            flexDirection: "column",
            textAlign: "center",
        },
        EditPermissionTargetTitle: {
            padding: "6px",
            color: theme.palette.white.main,
        },
        EditPermissionActions: {
            display: "flex",
            flexDirection: "row",
            minWidth: "250px",
        },
        EditPermissionAction: {
            flex: "1 1 0px",
            padding: "0px 6px 6px 6px",
            color: theme.palette.white.main,
        },
        EditPermissionRows: {
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#FFFFFF",
            listStyle: "none",
            margin: "0px",
            padding: "0px",
            minWidth: "100%",
        },
        EditPermissionParentRow: {
            padding: "0px",
            flexDirection: "row-reverse",
            alignItems: "center",
            boxShadow:
                "0px 2px 1px -1px rgb(10 10 10 / 20%), 0px 1px 1px 0px rgb(10 10 10 / 14%), 0px 1px 3px 0px rgb(10 10 10 / 12%)",
            //borderBottom: "1px solid #000000"
        },
        EditPermissionTargetHeader: {
            width: "100%",
            display: "flex",
            alignItems: "center",
        },
        EditPermissionExpanded: {
            padding: "0px",
        },
        EditPermissionExpandChildren: {
            display: "flex",
            justifyContent: "left",
            marginLeft: "12px",
            "& .MuiSvgIcon-root.Mui-expanded": {
                transform: "rotate(90deg)",
            },
            alignItems: "center",
        },
        EditPermissionResource: {
            display: "flex",
            flex: "1",
            justifyContent: "center",
            marginRight: "26px",
        },
        EditPermissionChildCount: {
            display: "flex",
            flex: "1",
            justifyContent: "right",
            marginRight: "12px",
        },
        EditPermissionTargetChecks: {
            marginLeft: "auto",
            justifyContent: "space-around",
            display: "flex",
            minWidth: "250px",
        },
        EditPermissionCheckbox: {
            flex: "1",
            justifyContent: "center",
            display: "flex",
        },
        EditPermissionChildRow: {
            display: "flex",
            alignItems: "center",
            minHeight: "50px",
            borderBottom: "1px solid #E5E5E5",
        },
        EditPermissionChildResource: {
            flex: "1 1 0px",
            textAlign: "center",
        },
    });
};

interface IOrganizationDetailProps {
    id?: string;
}

const OrganizationDetail: React.FC<
    React.PropsWithChildren<IOrganizationDetailProps>
> = ({ id }) => {
    const navigate = useNavigate();
    const classes = useStyles(styles);

    const authStore = useStore(AuthStore);
    const permStore = authStore.permStore;
    const messageStore = useStore(MessageStore);
    const orgStore = useStore(AuthStore).orgStore;

    const [avaDataOpen, setAvaDataOpen] = useState(false);
    const [avaDataLoading, setAvaDataLoading] = useState(false);
    const avaDataDatePickerStore = useMemo(
        () => new DatePickerComponentStore(),
        [],
    );

    const [validation, setValidation] = React.useState({});
    const [confirm, setConfirm] = React.useState(false);
    const [currentOrgId, setCurrentOrgId] = React.useState<string>();

    //Org Permissions Stuff
    const [permissionEditMode, setPermissionEditMode] = React.useState(false);
    //List of all possible permissions an organization could have
    const [possiblePermissions, setPossiblePermissions] =
        React.useState<ApplicationPermission[]>();
    //List for UI to keep track of which parent permissions are expanded
    const [expandedResources, setExpandedResources] =
        React.useState<string[]>();
    //List of enabled permissions, that updates as UI updates
    const [organizationPermissions, setOrganizationPermissions] =
        React.useState<ApplicationPermission[]>();

    const {
        value,
        registerSubmit,
        errors,
        onChange,
        isSubmitting,
        setValue,
        reset,
    } = useForm<Organization>(defaultValues, validation);

    const {
        organization,
        saveOrganization,
        isLoading,
        checkDbConnection,
        dbConnectionStatus,
    } = useOrganization({
        id,
    });

    const updateOrg = (
        val: string | string[] | boolean | Saml2BindingType,
        prop: keyof Organization,
    ) => {
        onChange(prop, val);
    };

    const save = () => {
        if ((value?.serviceSetup?.length || 0) > 5) {
            setConfirm(true);
        } else {
            return confirmSave();
        }
    };

    const handleCheckDbConnection = () => {
        const connectionString = value?.databaseConnectionString;
        const databaseVersion = Number(value?.databaseVersion);

        if (
            connectionString &&
            connectionString !== "" &&
            typeof databaseVersion === "number"
        ) {
            checkDbConnection(connectionString, databaseVersion);
        }
    };

    const confirmSave = () => {
        setConfirm(false);
        return saveOrganization(value || {});
    };

    const onClose = () => {
        setConfirm(false);
    };

    const onSuccess = (res: Organization) => {
        const connectionString = value?.databaseConnectionString;
        const databaseVersion = Number(value?.databaseVersion);

        if (
            (connectionString && connectionString.trim() !== "") ||
            !isNaN(databaseVersion)
        ) {
            if (connectionString) {
                checkDbConnection(connectionString, databaseVersion);
            } else {
                checkDbConnection("", databaseVersion);
            }
        } else {
            messageStore.logMessage(
                `Successfully saved organization`,
                "success",
            );
        }

        //This needs to be updated in the future to ensure synchronicity between database and stores on all organization properties
        if (orgStore.selectedOrganization !== null) {
            orgStore.selectedOrganization!.enhancedEvalStatus =
                res.enhancedEvalStatus;
            orgStore.selectedOrganization!.enhancedPermissionStatus =
                res.enhancedPermissionStatus;
        }

        if (!id && res?.id) {
            navigate(`/admin/organizations/${res.id}`);
        }
    };

    const onFail = (message: string) => {
        messageStore.logMessage(
            `Failed to save organization${message ? `: ${message}` : ""}`,
            "error",
        );
    };

    const updateContainerName = (val?: string | number) => {
        if (val) {
            if (!id && !value?.blobContainer) {
                onChange("blobContainer", createContainerName(String(val)));
            }
        } else {
            onChange("blobContainer", createContainerName(""));
        }
    };

    React.useEffect(() => {
        if (organization) {
            setValue(organization, false);
        }
    }, [organization, setValue]);

    React.useEffect(() => {
        if (currentOrgId !== id) {
            reset();
            setCurrentOrgId(id);
        }
    }, [currentOrgId, id, reset]);

    React.useEffect(() => {
        setValidation((prev) => ({
            ...prev,
            blobContainer: id
                ? {}
                : {
                      required: {
                          value: true,
                          message: "Blob container is required.",
                      },
                      minLength: {
                          value: 3,
                          message: "Must be at least 3 characters.",
                      },
                      maxLength: {
                          value: 63,
                          message: "Must be at most 63 characters.",
                      },
                      pattern: [
                          {
                              value: /^[a-z0-9]/,
                              message:
                                  "Must start with a lowercase letter or number.",
                          },
                          {
                              value: /\w*[a-z]\w*/,
                              message:
                                  "Must contain at least one lowercase letter.",
                          },
                          {
                              value: /^.*[^-_]$/,
                              message: "Cannot start or end with a hyphen.",
                          },
                          {
                              value: /[A-Z]/,
                              opposite: true,
                              message: "Uppercase letters are not allowed.",
                          },
                          {
                              value: /^[^-]+(?!.*--).+[^-]+$/,
                              message: "Consecutive hyphens are not allowed.",
                          },
                      ],
                  },
        }));
    }, [id]);

    let resources;

    if (!organizationPermissions) {
        const getOrgPermissions = async () => {
            if (organization?.id) {
                const perms = await permStore.getOrgPermissions(
                    organization?.id,
                );
                setOrganizationPermissions(perms);
            }
        };
        getOrgPermissions();
    }

    //Properly updates the list of final org permissions
    const updateOrgPermissions = (e, permission: ApplicationPermission) => {
        e.stopPropagation();

        const permissionsToChange: ApplicationPermission[] = [permission];

        const addPermissionEvaluateParents = (
            permission: ApplicationPermission,
        ) => {
            //If the permission has a parent and that parent isn't in the list, add it to the permissions to update.
            if (
                permission.parentPermission &&
                (organizationPermissions?.filter(
                    (perm) => perm.id === permission.parentPermission,
                ).length ?? 0) < 1
            ) {
                permissionsToChange.push(
                    possiblePermissions?.find(
                        (perm) => perm.id === permission.parentPermission,
                    ) ?? new ApplicationPermission(),
                );

                //If the parent has a parent run recursively
                if (
                    possiblePermissions?.find(
                        (perm) => perm.id === permission.parentPermission,
                    )?.parentPermission
                ) {
                    addPermissionEvaluateParents(
                        possiblePermissions?.find(
                            (perm) => perm.id === permission.parentPermission,
                        ) ?? new ApplicationPermission(),
                    );
                }
            }
        };

        const removePermissionEvaluateParent = (
            permission: ApplicationPermission,
        ) => {
            //If the permission has a parent and it's the only one in the list with that parent, add it to the permissions to update
            if (
                permission.parentPermission &&
                (organizationPermissions?.filter(
                    (perm) => perm.id === permission.parentPermission,
                ).length ?? 0) > 0 &&
                organizationPermissions?.filter(
                    (perm) =>
                        perm.parentPermission === permission.parentPermission,
                ).length === 1
            ) {
                permissionsToChange.push(
                    organizationPermissions?.find(
                        (perm) => perm.id === permission.parentPermission,
                    ) ?? new ApplicationPermission(),
                );

                //If the parent has a parent run recursively
                if (
                    possiblePermissions?.find(
                        (perm) => perm.id === permission.parentPermission,
                    )?.parentPermission
                ) {
                    removePermissionEvaluateParent(
                        possiblePermissions?.find(
                            (perm) => perm.id === permission.parentPermission,
                        ) ?? new ApplicationPermission(),
                    );
                }
            }
        };

        if (
            organizationPermissions?.filter((perm) => perm.id === permission.id)
                .length ??
            -1 > 0
        ) {
            if (permission.action === "Edit") {
                const viewPermission = possiblePermissions?.find(
                    (perm) =>
                        perm.resource === permission.resource &&
                        perm.action === "View",
                );
                if (viewPermission) {
                    permissionsToChange.push(viewPermission);
                    removePermissionEvaluateParent(viewPermission);
                }
            }
            removePermissionEvaluateParent(permission);
            setOrganizationPermissions(
                organizationPermissions?.filter(
                    (perm) =>
                        !(
                            permissionsToChange.filter((p) => p.id === perm.id)
                                .length > 0
                        ),
                ),
            );
        } else {
            if (permission.action === "Edit") {
                const viewPermission = possiblePermissions?.find(
                    (perm) =>
                        perm.resource === permission.resource &&
                        perm.action === "View",
                );
                if (
                    viewPermission &&
                    !organizationPermissions?.includes(viewPermission)
                ) {
                    permissionsToChange.push(viewPermission);
                    addPermissionEvaluateParents(viewPermission);
                }
            }
            addPermissionEvaluateParents(permission);
            setOrganizationPermissions(
                organizationPermissions?.concat(permissionsToChange),
            );
        }
    };

    if (possiblePermissions && possiblePermissions.length > 0) {
        // Recursive Permission Builder
        const createNestedPermissions = (resource: string) => {
            let permissions = possiblePermissions.filter(
                (perm) => perm.resource === resource,
            )!;
            let children = possiblePermissions.filter((perm) =>
                permissions
                    .flatMap((perm) => perm.id)
                    .includes(perm.parentPermission),
            );
            //If the resource has children, the resource needs to be an expanding dropdown, who children call this function recursively
            if (children && children.length > 0) {
                return (
                    <li key={resource}>
                        <Accordion
                            expanded={
                                expandedResources?.includes(resource) ?? false
                            }
                            onChange={() =>
                                expandedResources?.includes(resource)
                                    ? setExpandedResources(
                                          expandedResources.filter(
                                              (res) => res !== resource,
                                          ),
                                      )
                                    : setExpandedResources(
                                          expandedResources?.concat([
                                              resource,
                                          ]) ?? [resource],
                                      )
                            }
                        >
                            <AccordionSummary
                                className={classes.EditPermissionParentRow}
                            >
                                <div
                                    className={
                                        classes.EditPermissionTargetHeader
                                    }
                                >
                                    <div
                                        className={
                                            classes.EditPermissionExpandChildren
                                        }
                                    >
                                        <ArrowForwardIosSharp
                                            className={
                                                expandedResources?.includes(
                                                    resource,
                                                )
                                                    ? "Mui-expanded"
                                                    : ""
                                            }
                                            style={{ fontSize: "0.9rem" }}
                                        />
                                    </div>
                                    <Typography
                                        className={
                                            classes.EditPermissionResource
                                        }
                                    >
                                        {resource}
                                    </Typography>
                                </div>

                                <div
                                    className={
                                        classes.EditPermissionTargetChecks
                                    }
                                >
                                    {/* <div className={classes.EditPermissionCheckbox} >
                                        {possiblePermissions.filter(perm => perm.action === "View" && perm.resource === resource).length > 0 &&
                                            <Checkbox checked={!!(organizationPermissions?.find(perm => perm.action === "View" && perm.resource === resource))} color="primary" onClick={e => updateOrgPermissions(e, permissions.find(perm => perm.action === "View") ?? new ApplicationPermission())} />
                                        }
                                    </div>
                                    <div className={classes.EditPermissionCheckbox} >
                                        {possiblePermissions.filter(perm => perm.action === "Edit" && perm.resource === resource).length > 0 &&
                                            <Checkbox checked={!!(organizationPermissions?.find(perm => perm.action === "Edit" && perm.resource === resource))} color="primary" onClick={e => updateOrgPermissions(e, permissions.find(perm => perm.action === "Edit") ?? new ApplicationPermission())} />
                                        }
                                    </div> */}
                                </div>
                            </AccordionSummary>
                            <AccordionDetails
                                className={classes.EditPermissionExpanded}
                            >
                                <ul className={classes.EditPermissionRows}>
                                    {Array.from(
                                        new Set(
                                            children.flatMap(
                                                (child) => child.resource,
                                            ),
                                        ),
                                    ).map((child) =>
                                        createNestedPermissions(child),
                                    )}
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                    </li>
                );
            } else {
                //If the resource has no children (meaning it is probably a child), go ahead and return it.
                return (
                    <li
                        key={resource}
                        className={classes.EditPermissionChildRow}
                    >
                        <div className={classes.EditPermissionChildResource}>
                            {resource}
                        </div>
                        <div className={classes.EditPermissionTargetChecks}>
                            <div className={classes.EditPermissionCheckbox}>
                                {possiblePermissions.filter(
                                    (perm) =>
                                        perm.action === "View" &&
                                        perm.resource === resource,
                                ).length > 0 && (
                                    <Checkbox
                                        checked={
                                            !!organizationPermissions?.find(
                                                (perm) =>
                                                    perm.action === "View" &&
                                                    perm.resource === resource,
                                            ) ||
                                            !!organizationPermissions?.find(
                                                (perm) =>
                                                    perm.action === "Edit" &&
                                                    perm.resource === resource,
                                            )
                                        }
                                        disabled={
                                            !!organizationPermissions?.find(
                                                (perm) =>
                                                    perm.action === "Edit" &&
                                                    perm.resource === resource,
                                            )
                                        }
                                        color="primary"
                                        onClick={(e) =>
                                            updateOrgPermissions(
                                                e,
                                                permissions.find(
                                                    (perm) =>
                                                        perm.action === "View",
                                                ) ??
                                                    new ApplicationPermission(),
                                            )
                                        }
                                    />
                                )}
                            </div>
                            <div className={classes.EditPermissionCheckbox}>
                                {possiblePermissions.filter(
                                    (perm) =>
                                        perm.action === "Edit" &&
                                        perm.resource === resource,
                                ).length > 0 && (
                                    <Checkbox
                                        checked={
                                            !!organizationPermissions?.find(
                                                (perm) =>
                                                    perm.action === "Edit" &&
                                                    perm.resource === resource,
                                            )
                                        }
                                        color="primary"
                                        onClick={(e) =>
                                            updateOrgPermissions(
                                                e,
                                                permissions.find(
                                                    (perm) =>
                                                        perm.action === "Edit",
                                                ) ??
                                                    new ApplicationPermission(),
                                            )
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    </li>
                );
            }
        };

        //Uniquely iterate through resources, we will consider actions later on in the checkbox UI
        resources = Array.from(
            new Set(possiblePermissions.flatMap((perm) => perm.resource)),
        ).map((resource) => {
            let permissions = possiblePermissions.filter(
                (perm) => perm.resource === resource,
            )!;
            let children = possiblePermissions.filter((perm) =>
                permissions
                    .flatMap((perm) => perm.id)
                    .includes(perm.parentPermission),
            );
            //If the resource has a parent, it will be rendered later on in the loop
            if (!permissions.find((perm) => perm.parentPermission)) {
                //If the resource has children, it will call this helper function that creates it, and it's children
                if (children && children.length > 0) {
                    return createNestedPermissions(resource);
                } else {
                    //If the resource has no children and no parent, go ahead and return it
                    return (
                        <li
                            key={resource}
                            className={classes.EditPermissionChildRow}
                        >
                            <div
                                className={classes.EditPermissionChildResource}
                            >
                                {resource}
                            </div>
                            <div className={classes.EditPermissionTargetChecks}>
                                <div className={classes.EditPermissionCheckbox}>
                                    {possiblePermissions.filter(
                                        (perm) =>
                                            perm.action === "View" &&
                                            perm.resource === resource,
                                    ).length > 0 && (
                                        <Checkbox
                                            checked={
                                                !!organizationPermissions?.find(
                                                    (perm) =>
                                                        perm.action ===
                                                            "View" &&
                                                        perm.resource ===
                                                            resource,
                                                ) ||
                                                !!organizationPermissions?.find(
                                                    (perm) =>
                                                        perm.action ===
                                                            "Edit" &&
                                                        perm.resource ===
                                                            resource,
                                                )
                                            }
                                            disabled={
                                                !!organizationPermissions?.find(
                                                    (perm) =>
                                                        perm.action ===
                                                            "Edit" &&
                                                        perm.resource ===
                                                            resource,
                                                )
                                            }
                                            color="primary"
                                            onClick={(e) =>
                                                updateOrgPermissions(
                                                    e,
                                                    permissions.find(
                                                        (perm) =>
                                                            perm.action ===
                                                            "View",
                                                    ) ??
                                                        new ApplicationPermission(),
                                                )
                                            }
                                        />
                                    )}
                                </div>
                                <div className={classes.EditPermissionCheckbox}>
                                    {possiblePermissions.filter(
                                        (perm) =>
                                            perm.action === "Edit" &&
                                            perm.resource === resource,
                                    ).length > 0 && (
                                        <Checkbox
                                            checked={
                                                !!organizationPermissions?.find(
                                                    (perm) =>
                                                        perm.action ===
                                                            "Edit" &&
                                                        perm.resource ===
                                                            resource,
                                                )
                                            }
                                            color="primary"
                                            onClick={(e) =>
                                                updateOrgPermissions(
                                                    e,
                                                    permissions.find(
                                                        (perm) =>
                                                            perm.action ===
                                                            "Edit",
                                                    ) ??
                                                        new ApplicationPermission(),
                                                )
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </li>
                    );
                }
            }
            return <li key={resource}></li>;
        });
    } else if (possiblePermissions === undefined) {
        const getPermissions = async () => {
            if (organization?.id) {
                const perms = await permStore.getAllPermissions(
                    organization?.id,
                );
                setPossiblePermissions(perms);
            }
        };
        getPermissions();
    }

    const exitPermissionMode = () => {
        setOrganizationPermissions(undefined);
        setPermissionEditMode(false);
    };

    const saveOrgPermissions = async () => {
        try {
            const numberPermissionsUpdated =
                await permStore.updateOrgPermissions(
                    organization?.id ?? "",
                    organizationPermissions ?? [],
                );
            messageStore.logMessage(
                `Successfully updated ${numberPermissionsUpdated} permission(s)`,
                "success",
            );
        } catch {
            messageStore.logMessage(
                `Error updating permission(s). Please try again.`,
                "error",
            );
        }
    };

    const handleAvaDataDownload = React.useCallback(async () => {
        try {
            setAvaDataLoading(true);
            const startDate = avaDataDatePickerStore.beginDate;
            const endDate = avaDataDatePickerStore.endDate;
            const response = await new AcxAgentService().downloadChats(
                startDate?.toISOString(),
                endDate?.toISOString(),
            );
            const blob = await response.blob();

            if (blob.size === 0)
                messageStore.logInfo(
                    "No Ava chat data for the specified date range.",
                );
            else downloadFromBlob(blob, "ava.csv");
            setAvaDataOpen(false);
        } catch (err: unknown) {
            messageStore.logError(JSON.stringify(err));
        } finally {
            setAvaDataLoading(false);
        }
    }, [
        avaDataDatePickerStore.beginDate,
        avaDataDatePickerStore.endDate,
        messageStore,
    ]);

    return (
        <>
            <div className={classes.pageContainer}>
                {!permissionEditMode ? (
                    <>
                        <Grid
                            style={{
                                marginTop: theme.spacing(1),
                                marginBottom: theme.spacing(1),
                            }}
                            container
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Grid item>
                                <Typography variant="subtitle1">
                                    Org Details
                                </Typography>
                            </Grid>

                            <div className={classes.OrgDetailButtons}>
                                {authStore.isUserUltra() && !!organization && (
                                    <AcxButton
                                        color="secondary"
                                        leftRightSpacing={0}
                                        startIcon={<AddSharp />}
                                        onClick={() => {
                                            navigate(
                                                "/admin/organizations/add",
                                            );
                                        }}
                                    >
                                        Create New Organization
                                    </AcxButton>
                                )}

                                {authStore.isUserUltra() && !!organization && (
                                    <Grid item>
                                        <AcxButton
                                            leftRightSpacing={0}
                                            fullWidth={false}
                                            onClick={() => setAvaDataOpen(true)}
                                        >
                                            Ava Data
                                        </AcxButton>
                                        <AcxDialog
                                            isOpen={avaDataOpen}
                                            onClose={() =>
                                                setAvaDataOpen(false)
                                            }
                                            title="Export Ava Chat Data"
                                            dialogContentChildren={
                                                <Grid container>
                                                    <Grid item>
                                                        <AcxDateRangeInput
                                                            hideLabel
                                                            sideBar
                                                            doubleCalendar
                                                            maxWidth="none"
                                                            datePickerStore={
                                                                avaDataDatePickerStore
                                                            }
                                                            defaultStartDate={avaDataDatePickerStore.beginDate.startOf(
                                                                "day",
                                                            )}
                                                            defaultEndDate={
                                                                avaDataDatePickerStore.endDate
                                                            }
                                                            onSelect={(
                                                                start,
                                                                end,
                                                            ) => {
                                                                avaDataDatePickerStore.setBeginDate(
                                                                    start,
                                                                );
                                                                avaDataDatePickerStore.setEndDate(
                                                                    end,
                                                                );
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            }
                                        >
                                            <AcxButton
                                                leftRightSpacing={0}
                                                fullWidth={false}
                                                loading={avaDataLoading}
                                                onClick={handleAvaDataDownload}
                                            >
                                                Download
                                            </AcxButton>
                                        </AcxDialog>
                                    </Grid>
                                )}

                                {authStore.isUserUltra() &&
                                    organization?.enhancedPermissionStatus && (
                                        <Grid item>
                                            <AcxButton
                                                onClick={() =>
                                                    setPermissionEditMode(true)
                                                }
                                                color="secondary"
                                                leftRightSpacing={0}
                                                buttonDisabled={isSubmitting}
                                            >
                                                Edit Organization Permissions
                                            </AcxButton>
                                        </Grid>
                                    )}

                                {!organization && !isLoading && (
                                    <Grid item>
                                        <AcxButton
                                            onClick={() => navigate(-1)}
                                            color="secondary"
                                            leftRightSpacing={0}
                                            // loading={isLoading || isSubmitting}
                                            // buttonDisabled={isSubmitting}
                                        >
                                            Cancel
                                        </AcxButton>
                                    </Grid>
                                )}

                                {authStore.canUserEdit(
                                    "Organization Details",
                                ) && (
                                    <Grid item>
                                        <AcxButton
                                            onClick={registerSubmit(save, {
                                                onSuccess,
                                                onFail,
                                            })}
                                            color="secondary"
                                            leftRightSpacing={0}
                                            loading={isLoading || isSubmitting}
                                            buttonDisabled={isSubmitting}
                                        >
                                            Save Changes
                                        </AcxButton>
                                    </Grid>
                                )}
                            </div>
                        </Grid>

                        {authStore.canUserEdit("Organization Details") && (
                            <OrganizationDetails
                                updateOrg={updateOrg}
                                isLoading={isLoading}
                                org={value}
                                fieldErrors={errors?.fieldErrors}
                                formErrors={errors?.form}
                                updateContainerName={updateContainerName}
                                checkDbConnection={handleCheckDbConnection}
                                dbConnectionStatus={
                                    dbConnectionStatus ??
                                    DB_CONNECTION_STATUS.DEFAULT
                                }
                            />
                        )}

                        {authStore.canUserEdit("Organization Details") &&
                            !!id && <MetadataConfigurationWrapper orgId={id} />}

                        {organization &&
                            authStore.canUserEdit("Organization Details") && (
                                <SAMLSettings
                                    defaultValues={{
                                        enableSamlSso:
                                            !!organization?.saml2EntityId,
                                        authenticxDomain:
                                            organization?.saml2AuthenticxDomain,
                                        bindingType:
                                            organization?.saml2BindingType,
                                        certificateInPemFormat:
                                            organization?.saml2CertificateInPemFormat,
                                        entityId: organization?.saml2EntityId,
                                        loginButtonText:
                                            organization?.saml2LoginButtonText,
                                        samlResponseEmailPropertyName:
                                            organization?.saml2ResponseEmailPropertyName,
                                        samlResponseFirstNamePropertyName:
                                            organization?.saml2ResponseFirstNamePropertyName,
                                        samlResponseLastNamePropertyName:
                                            organization?.saml2ResponseLastNamePropertyName,
                                        singleSignOnServiceUrl:
                                            organization?.saml2SingleSignOnServiceUrl,
                                        singleSignOutServiceUrl:
                                            organization?.saml2SingleSignOutServiceUrl,
                                    }}
                                    isLoading={isLoading}
                                    organizationId={id}
                                />
                            )}

                        {authStore.canUserEdit("Organization Details") && (
                            <IntegrationSettings orgId={id} />
                        )}

                        <ServiceSetup
                            updateOrg={updateOrg}
                            setup={value?.serviceSetup}
                            fieldErrors={errors?.fieldErrors}
                            disabled={
                                !authStore.canUserEdit("Organization Details")
                            }
                        />
                    </>
                ) : (
                    <>
                        <div className={classes.EditPermissionHeader}>
                            <ArrowBack
                                onClick={exitPermissionMode}
                                className={classes.BackIcon}
                            />
                            <Typography variant="subtitle1">
                                Organization Permissions
                            </Typography>

                            <div className={classes.SavePermissionsButton}>
                                <AcxButton
                                    color="secondary"
                                    leftRightSpacing={0}
                                    onClick={saveOrgPermissions}
                                >
                                    Save Permissions
                                </AcxButton>
                            </div>
                        </div>
                        <div className={classes.EditPermissionBody}>
                            <div className={classes.EditPermissionTableHeader}>
                                <Typography
                                    className={classes.PermissionsTitle}
                                    variant="subtitle2"
                                >
                                    Resources
                                </Typography>
                                <div className={classes.EditPermissionTargets}>
                                    <div
                                        className={classes.EditPermissionTarget}
                                    >
                                        <Typography
                                            className={
                                                classes.EditPermissionTargetTitle
                                            }
                                            variant="body2"
                                        >
                                            {organization?.name}
                                        </Typography>
                                        <div
                                            className={
                                                classes.EditPermissionActions
                                            }
                                        >
                                            <Typography
                                                className={
                                                    classes.EditPermissionAction
                                                }
                                                variant="body1"
                                            >
                                                View
                                            </Typography>
                                            <Typography
                                                className={
                                                    classes.EditPermissionAction
                                                }
                                                variant="body1"
                                            >
                                                Edit
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul className={classes.EditPermissionRows}>
                                {resources}
                            </ul>
                        </div>
                    </>
                )}
            </div>

            {confirm && (
                <AcxDialog
                    isOpen={!!confirm}
                    onClose={onClose}
                    title="Save organization"
                    text="Are you sure that you would like to commit these changes?"
                    subTitle="Authenticx recommends five tiers or less, if applicable."
                >
                    <AcxButton
                        onClick={registerSubmit(confirmSave, { onSuccess })}
                        color="secondary"
                        leftRightSpacing={0}
                        loading={isLoading || isSubmitting}
                        buttonDisabled={isSubmitting}
                        fullWidth={false}
                    >
                        Confirm
                    </AcxButton>
                </AcxDialog>
            )}
        </>
    );
};

export default OrganizationDetail;
