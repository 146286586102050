import { styled } from "@mui/material";
import AcxButton from "components/UI/AcxButton";

const Suggestion = styled(AcxButton)(({ theme }) => ({
    paddingInline: theme.spacing(2),
    paddingBlock: theme.spacing(1),
    border: "1px solid",
    borderColor: theme.palette.primary[500],
    color: theme.palette.primary[500],
    backgroundColor: theme.palette.white.main,
    borderRadius: "9999px",
    height: "min-content",
    textWrap: "wrap",
    textAlign: "start",
    ":hover": {
        backgroundColor: theme.palette.primary[100],
    },
}));

Suggestion.defaultProps = {
    leftRightSpacing: 0,
    fullWidth: false,
};

export { Suggestion };
