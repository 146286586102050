import { Grid } from "@mui/material";
import AgentLoading from "animations/AgentLoading";
import React from "react";
import { AgentAvatar } from "../Branding/AgentAvatar";
import { observer } from "mobx-react";
import { useStore } from "utils/useStore";
import AgentChatStore from "../AgentChatStore";
import { MessageAuthor } from "../Models/MessageAuthor";
import { ThreadMessageGroup } from "../ThreadMessageGroup";
import { ThreadMessage } from "../Models/ThreadMessage";
import { ResponseTime } from "../Models/ResponseTime";
import { AgentState } from "../Models/AgentState";

/**
 * If the agent is in a loading state, the proper loading indicator is rendered
 * based on the total time the agent has been waiting.
 */
export const MessageLoading = observer(() => {
    const store = useStore(AgentChatStore);

    if (store.state !== AgentState.Waiting) return null;

    const responseTime = store.responseTime;
    if (!responseTime) return null;

    if (responseTime === ResponseTime.Short)
        return (
            <Grid container alignItems="center">
                <AgentAvatar />
                <Grid item sx={{ width: "80px", height: "40px", padding: 0 }}>
                    <AgentLoading />
                </Grid>
            </Grid>
        );

    if (responseTime > ResponseTime.Short) {
        const messages: ThreadMessage[] = [];

        if (responseTime >= ResponseTime.Medium) {
            messages.push(
                new ThreadMessage(
                    "This is taking a little bit longer, I'm working on it, please hold!",
                ),
            );
        }

        if (responseTime >= ResponseTime.Long) {
            messages.push(
                new ThreadMessage(
                    "Don't worry, still working on it! May take up to a minute",
                ),
            );
        }

        return (
            <ThreadMessageGroup
                id="agent-thread-loading"
                author={MessageAuthor.Agent}
                isMostRecent
                cancelledStreaming={false}
                messages={messages}
            />
        );
    }

    return null;
});
