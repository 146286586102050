import React, { CSSProperties } from "react";
import AcxSelect from "./AcxSelect";

export interface IAcxSelectSingle<S> {
    options: S[] | { label: string; options: S[] }[];
    id: string;
    valueField: string;
    labelField: string;
    onChange?: (arg0: S) => void;
    placeholder?: string;
    defaultValue?: S;
    inputLabel?: string;
    enableNoSelection?: boolean;
    editIcon?: boolean;
    onEditClick?: () => void;
    isClearable?: boolean;
    isLoading?: boolean;
    menuPortalTarget?: HTMLElement | null | undefined;
    shrink?: boolean;
    infoText?: React.ReactNode;
    fullWidth?: boolean;
    minWidth?: string;
    isDisabled?: boolean;
    menuPlacement?: "top" | "bottom" | "auto";
    customStyle?: Record<string, (provided, state) => CSSProperties>;
    maxMenuHeightPx?: number;
    required?: boolean;
    customFilter?: (option, rawInput: string) => boolean;
    groupCollapse?: boolean;
    containerHeight?: string | "auto";
    colorField?: string;
    error?: boolean;
    helperText?: React.ReactNode;
    dropdownIndicator?: React.ReactNode;
    dropdownMenuEndChildren?: React.ReactNode;
    noValue?: boolean;
    isSearchable?: boolean;
    alternateItemColor?: string;
    labelIcon?: React.ReactNode;
}

export default function AcxSelectSingle<S>(props: IAcxSelectSingle<S>) {
    const onChange = (value: S) => {
        if (props.onChange) {
            props.onChange(value as S);
        }
    };

    return (
        <AcxSelect
            id={props.id}
            required={props.required}
            placeholder={props.placeholder}
            maxMenuHeightPx={props.maxMenuHeightPx}
            onChange={onChange}
            customStyle={props.customStyle}
            options={props.options}
            isMulti={false}
            defaultValue={props.defaultValue}
            valueField={props.valueField}
            labelField={props.labelField}
            inputLabel={props.inputLabel ? props.inputLabel : undefined}
            enableNoSelection={props.enableNoSelection}
            onEditClick={props.onEditClick}
            editIcon={props.editIcon}
            isClearable={props.isClearable}
            isLoading={props.isLoading}
            menuPortalTarget={props.menuPortalTarget}
            shrink={props.shrink}
            infoText={props.infoText}
            fullWidth={props.fullWidth}
            minWidth={props.minWidth}
            isDisabled={props.isDisabled}
            menuPlacement={props.menuPlacement}
            customFilter={props.customFilter}
            groupCollapse={props.groupCollapse}
            containerHeight={props.containerHeight}
            colorField={props.colorField}
            helperText={props.helperText}
            error={props.error}
            dropdownIndicator={props.dropdownIndicator}
            dropdownMenuEndChildren={props.dropdownMenuEndChildren}
            noValue={props.noValue}
            isSearchable={props.isSearchable}
            alternateItemColor={props.alternateItemColor}
            labelIcon={props.labelIcon}
        />
    );
}
