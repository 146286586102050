import { ClassifierResultAnswerTag } from "models/ClassifierResultAnswerTag";
import { QuestionDependency } from "models/Question";

export enum LmType {
    Hierarchy,
    Always,
    Workflow,
    "Safety Event",
}

export type Module = {
    id: string;
    organizationId: string;
    renderPlacement: number | null;
    organizationStructureMemberIds: string[];
    questions: Question[];
    lmType: LmType;
    name: string;
    order?: number;
    questionsToDelete?: string[];
    tagsToDelete?: string[];
    isActive: boolean;
    mediaTypes?: number[];
    description?: string;
    displayName?: string;
};

export type NewModule = {
    organizationId: string;
    name: string;
    lmType: LmType;
    organizationStructureMemberIds: string[];
    questions: Question[];
};

export type Question = {
    id?: string;
    classifierId?: string;
    questionText: string;
    answerType: AnswerType;
    helperText: string;
    weight: number;
    required: boolean;
    score?: number;
    tags?: Tag[];
    shortQuestionText?: string;
    category?: string;
    numberOfResponses?: number; //only applies to star rating
    questions?: Question[];
    $uiKey?: string;
    variation?: AnswerType;
    answerTypeId?: string;
    isActive: boolean;
    isNew?: boolean; // to determine between added and persisted questions
    dependencies?: QuestionDependency;
    maxLength?: number;
    /**
     * Used for default questions added for SE lm type to disable editing
     */
    isDefault?: boolean;
};
/*
There are the following answer types:
Scaled Response
Tag Response
Scored Tag Response
Star Rating
Scaled Boolean Response
*/
export type AnswerType = {
    answerTypeName: string;
    variation: string; //applies only to Scaled Boolean Response - this will be Radio or Checkbox
    isScored?: boolean;
    id: string;
    variations?: AnswerType[];
    isFillInAnswer?: boolean;
    excludeFromModuleScoreCalculation?: boolean;
};

export type Tag = {
    order: number;
    id: string;
    value: string;
    scoredValue: number | null;
    isActive: boolean;
    classifierResultAnswerTag: ClassifierResultAnswerTag;
};
