import { action, makeObservable, observable } from "mobx";
import { uuidv4 } from "utils/helpers";

export interface IThreadMessage {
    readonly id: string;
    content: string;
    dynamicContent?: () => string;
}

export class ThreadMessage implements IThreadMessage {
    readonly id: string;
    @observable content: string;
    @observable cancelled: boolean;

    constructor(content: string) {
        makeObservable(this);

        this.id = uuidv4();
        this.content = content;
        this.cancelled = false;
    }

    @action public setContent(content: string) {
        this.content = content;
    }

    @action public appendContent(content: string) {
        this.content += content;
    }

    @action public setCancelled(cancelled: boolean) {
        this.cancelled = cancelled;
    }
}

export class TemplatedThreadMessage implements IThreadMessage {
    readonly id: string;
    @observable template: string;
    @observable arg: unknown;
    @observable content: string;
    @observable buildContent: (arg: unknown) => string;
    @observable dynamicContent = () => this.buildContent(this.arg);
    
    constructor(template: string, arg: unknown, buildContent: (arg: unknown) => string) {
        makeObservable(this);
        this.id = uuidv4();
        this.template = template;
        this.arg = arg;
        this.buildContent = buildContent;
    }

}