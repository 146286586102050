import ErrorIcon from "@mui/icons-material/Error";
import * as React from "react";
import { useEffect } from "react";
import { isUndefinedType } from "../../../utils/TypeGuards";
import AcxLoadingIndicator from "../AcxLoadingIndicator";
import MicroPlayerControl, { PlayStatus } from "./MicroPlayerControl";

export enum LoadStatus {
    NotLoaded = 1,
    Loading,
    Loaded,
    Failed,
}

interface Props {
    getFunction?: () => Promise<string | Blob>;
    controlButtonClass?: string;
    pauseButtonClass?: string;
    disabled?: boolean;
    isLoading?: boolean;
    style?: React.CSSProperties;
    mediaUrl?: string;
    iconHeight?: number;
    iconWidth?: number;
}

function AcxMicroPlayer(props: Props) {
    const [playStatus, setPlayStatus] = React.useState<PlayStatus>(
        PlayStatus.Stopped,
    );
    const [loadStatus, setLoadStatus] = React.useState(LoadStatus.NotLoaded);
    const [canPlayThrough, setCanPlayThrough] = React.useState(false);

    useEffect(() => {
        if (!isUndefinedType(props.isLoading)) {
            setLoadStatus(
                props.isLoading ? LoadStatus.Loading : LoadStatus.NotLoaded,
            );
        }
    }, [props.isLoading]);

    const onAudioPause = () => {
        setPlayStatus(PlayStatus.Paused);
    };

    const onAudioPlay = () => {
        setPlayStatus(PlayStatus.Play);
    };

    function onClick() {
        if (loadStatus === LoadStatus.NotLoaded) downloadFile();
        if (loadStatus === LoadStatus.Loaded) {
            let element = audioElement.current;

            if (!element) return;
            if (playStatus === PlayStatus.Play) {
                element.pause();
                setPlayStatus(PlayStatus.Paused);
            } else {
                element.play();
                setPlayStatus(PlayStatus.Play);
            }
        }
    }

    const onReady = React.useCallback(() => {
        if (audioElement.current) audioElement.current.play();
    }, []);

    const onCanPlayThrough = () => {
        setCanPlayThrough(true);
    };

    const onLoadedData = () => {
        setLoadStatus(LoadStatus.Loaded);
    };

    const onError = () => {
        setLoadStatus(LoadStatus.Failed);
    };
    const audioElement = React.useRef<HTMLAudioElement>(null);

    if (audioElement.current) {
        audioElement.current.addEventListener("error", onError);
        audioElement.current.addEventListener(
            "canplaythrough",
            onCanPlayThrough,
        );
        audioElement.current.addEventListener("loadeddata", onLoadedData);
    }

    const downloadFile = async () => {
        setLoadStatus(LoadStatus.Loading);
        if (!props.mediaUrl) {
            try {
                const data: any = await props.getFunction?.();
                let blobUrl;

                if (typeof data === "string") {
                    var binary_string = window.atob(data);
                    var len = binary_string.length;
                    var bytes = new Uint8Array(len);
                    for (var i = 0; i < len; i++) {
                        bytes[i] = binary_string.charCodeAt(i);
                    }

                    let blob = new Blob([bytes], { type: "audio/wav" });

                    blobUrl = URL.createObjectURL(blob);
                } else if (data instanceof Blob) {
                    blobUrl = URL.createObjectURL(data);
                }

                if (audioElement.current && blobUrl) {
                    audioElement.current.src = blobUrl;
                    audioElement.current.load();
                }
            } catch (ex) {
                setLoadStatus(LoadStatus.Failed);
            }
        } else {
            if (audioElement.current) {
                audioElement.current.src = props.mediaUrl;
                audioElement.current.load();
            }
        }
    };

    useEffect(() => {
        if (
            audioElement.current &&
            loadStatus === LoadStatus.Loaded &&
            canPlayThrough
        ) {
            onReady();
        }
    }, [loadStatus, canPlayThrough, onReady]);

    var content;

    if (loadStatus === LoadStatus.Failed) {
        content = <ErrorIcon color="error" />;
    } else if (loadStatus === LoadStatus.Loading) {
        content = (
            <div>
                <AcxLoadingIndicator
                    color="secondary"
                    alternate="PuffLoader"
                    size={!!props.iconHeight && props.iconHeight > 40 ? 64 : 32} // adjust loading size based on player button size
                />
            </div>
        );
    } else {
        content = (
            <MicroPlayerControl
                disabled={props.disabled}
                controlButtonClass={props.controlButtonClass}
                pauseButtonClass={props.pauseButtonClass}
                onClick={onClick}
                color={"primary"}
                status={playStatus}
                style={props.style}
                iconHeight={props.iconHeight}
                iconWidth={props.iconWidth}
            />
        );
    }

    return (
        <>
            {content}
            <audio
                onPlay={onAudioPlay}
                onPause={onAudioPause}
                ref={audioElement}
                controls
                hidden
                controlsList="nodownload"
            />
        </>
    );
}
export default AcxMicroPlayer;
