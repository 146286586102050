import { Grid, IconButton, Tooltip } from "@mui/material";
// import createStyles from "@mui/styles/createStyles";
import SyncIcon from "@mui/icons-material/Sync";
// import { OrgSelectWrapper } from "components/Admin/AdminUIComponents/OrgSelect";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
// import useStyles from "../../../../../../Styles/Styles";
import { useStore } from "../../../../../../utils/useStore";
import ConfirmationDialog from "../../../../../UI/AcxConfirmationDialog";
import { CustomControlItem } from "../../../../../UI/AcxTable/AcxControlledTable";
import {
    LoadSampleFilesTask,
    SourceFilesStepStore,
} from "../../Stores/SourceFilesStepStore";
import SourceFilesTable from "../SourceFilesTable";
import DirectoryTable from "./DirectoryTable";
import { AuthStore } from "stores/AuthStore";

interface OwnProps {}

type Props = OwnProps;

// const styles = () =>
//     createStyles({
//         filterBar: {
//             height: "80px",
//         },
//     });

const SourceFilesStep: FunctionComponent<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = observer((props) => {
    // const classes = useStyles(styles);
    const store = useStore(SourceFilesStepStore);
    const auth = useStore(AuthStore);
    console.log("source file step function executing");
    store.orgSelectStore.selectOrg(auth.orgStore.selectedOrganization);

    const sourceFileTableControls: CustomControlItem[] = [
        {
            controlElement: (
                <Tooltip title={"Refresh"} placement={"top"}>
                    <IconButton onClick={() => store.refresh?.()} size="large">
                        <SyncIcon />
                    </IconButton>
                </Tooltip>
            ),
            xs: 1,
            sm: 1,
            md: 1,
            lg: 1,
            xl: 1,
        },
    ];

    // const orgSelectWrapper = (
    //     <OrgSelectWrapper
    //         id={"source-files-step-org"}
    //         orgSelectStore={store.orgSelectStore}
    //         disableGlobalOrgChange
    //     />
    // );

    return (
        <>
            <ConfirmationDialog
                onClose={store.closeSampleFileDialog}
                title={"Files"}
                maxWidth={"xl"}
                errorMessage={store.getTaskError(LoadSampleFilesTask)?.message}
                noControls
                isOpen={store.showSampleFilesDialog}
                content={
                    <SourceFilesTable
                        tableStoreFragment={store.filesDialogtableStore}
                        enablePaging
                        controls={sourceFileTableControls}
                        loading={store.getTaskLoading(LoadSampleFilesTask)}
                        metaLabels={store.metaLabels}
                    />
                }
            />

            <Grid
                container
                item
                xs={12}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                style={{ height: "100%" }}
            >
                {/* {store.orgSelectStore.showOrgSelect && (
                    <Grid
                        item
                        xs={12}
                        container
                        spacing={1}
                        alignItems="center"
                        justifyContent={"flex-start"}
                        className={classes.filterBar}
                    >
                        <Grid item>
                            <Typography component="span" variant="h5">
                                Filter by organization:
                            </Typography>
                        </Grid>

                        <Grid item xs={4}>
                            {orgSelectWrapper}
                        </Grid>
                    </Grid>
                )} */}
                <Grid item xs={12} style={{ height: "100%" }}>
                    <DirectoryTable />
                </Grid>
            </Grid>
        </>
    );
});

export default SourceFilesStep;
