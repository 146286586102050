import { Dialog, Grid } from "@mui/material";
import React from "react";
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@material-ui/core";
import theme from "Theme/AppTheme";
import AcxButton from "components/UI/AcxButton";
import { customSecondaryButtonStyle } from "utils/Style";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

interface DeleteFilterDialogProps {
    isOpen: boolean;
    onCancel?: () => void;
    onDelete?: () => void | Promise<void>;
    text?: {
        title?: string;
        content?: string;
        confirm?: string;
        cancel?: string;
    };
    hideAlertIcon?: boolean;
    loading?: boolean;
}

const AcxDeleteDialog: React.FC<DeleteFilterDialogProps> = ({
    isOpen,
    onCancel,
    onDelete,
    text: { title, content, confirm, cancel } = {},
    hideAlertIcon,
    loading,
}) => {
    return (
        <Dialog open={isOpen}>
            <Grid container width={theme.spacing(53)}>
                <Grid
                    container
                    item
                    padding={3}
                    justifyContent="center"
                    borderRadius={theme.spacing(1)}
                    direction="column"
                    xs={12}
                >
                    <Grid item>
                        <DialogTitle style={{ padding: 0 }}>
                            <Grid container direction="column">
                                {!hideAlertIcon && (
                                    <Grid
                                        item
                                        sx={{
                                            textAlign: "center",
                                            marginBottom: theme.spacing(1),
                                        }}
                                    >
                                        <WarningRoundedIcon
                                            color="error"
                                            fontSize="large"
                                        />
                                    </Grid>
                                )}
                                <Grid item>
                                    <Typography
                                        style={{
                                            fontSize: "20px",
                                            fontWeight: 600,
                                            padding: 0,
                                            textAlign: "center",
                                        }}
                                    >
                                        {title ?? "Delete"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                    </Grid>
                    <Grid item>
                        <DialogContent>
                            <Typography
                                style={{
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    color: theme.palette.gray.main,
                                    padding: 0,
                                    textAlign: "center",
                                }}
                            >
                                {content ?? "Are you sure you want to delete?"}
                            </Typography>
                        </DialogContent>
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    padding={3}
                    justifyContent="center"
                    borderRadius={theme.spacing(1)}
                    xs={12}
                >
                    <Grid item>
                        <DialogActions style={{ padding: 0 }}>
                            <AcxButton
                                customRootStyles={customSecondaryButtonStyle}
                                onClick={onCancel}
                                buttonDisabled={loading}
                            >
                                {cancel ?? "Cancel"}
                            </AcxButton>
                            <AcxButton
                                customRootStyles={customSecondaryButtonStyle}
                                rootStyle={{
                                    color: theme.palette.error.main,
                                }}
                                onClick={onDelete}
                                loading={loading}
                            >
                                {confirm ?? "Delete"}
                            </AcxButton>
                        </DialogActions>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default AcxDeleteDialog;
