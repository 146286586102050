import {
    AppBar,
    CssBaseline,
    Grid,
    Stack,
    Theme,
    Toolbar,
} from "@mui/material";
import { ThemeProvider as ThemeProviderV5 } from "@mui/material/styles";
import StylesProvider from "@mui/styles/StylesProvider";
import createStyles from "@mui/styles/createStyles";
import { CSSProperties } from "@mui/styles/withStyles";
import { styled } from "@mui/system";
import useStyles from "Styles/Styles";
import theme, { generateClassName } from "Theme/AppTheme";
import AgentChatMenuIcon from "components/Agent/AgentChatMenuIcon";
import AnalyticsHeap from "components/Analytics/Heap";
import ClassifiersLayout from "components/Classifiers/ClassifiersLayout";
import Conversations from "components/Conversations/Conversations";
import ManagerInteractions from "components/ManagerInteractions/ManagerInteractions";
import MontageLibrary from "components/Montage/MontageLibrary";
import NlpLayout from "components/NLP/NlpLayout";
import NotificationMenuIcon from "components/Notifications/NotificationMenuIcon";
import Reports from "components/Reports/Reports";
import ReportsRv2 from "components/ReportsRv2/ReportsRv2";
import SignalsLayout from "components/Signals/SignalsLayout";
import ComponentTestView from "components/TestComponents/AcxComponentTestView";
import Workflows from "components/Workflows/Workflows";
import { observer } from "mobx-react";
import { AppDomains } from "models/Permission/AppDomains";
import React, { useEffect, useMemo, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AuthStore } from "stores/AuthStore";
import hexToRGB from "utils/hexToRGB";
import { useStore } from "utils/useStore";
import logo from "../../authenticx-green.png";
import { LayoutDrawerStore } from "../../stores/Layout/LayoutDrawerStore";
import { useResizeObserver } from "../../utils/useResizeObserver";
import AgentCoaching from "../Agents/Coaching/AgentCoaching";
import AgentDashboard from "../Agents/Dashboard/AgentDashboard";
import AnalystDashboard from "../Dashboard/Analyst/AnalystDashboard";
import LandingDashboard from "../Dashboard/Landing/LandingDashboard";
import InitiativeView from "../Initiatives/InitiativeView";
import MessageStore from "../ManagerInteractions/Stores/MessageStore";
import ClassifierTranscription from "../TranscriptVisual/ClassifierTranscription/Components/Main/ClassifierTranscription";
import AcxSnackBar from "../UI/AcxSnackBar";
import AcxDrawer from "../UI/Drawer/AcxDrawer";
import EvaluationSearch from "../UtilityComponents/EvaluationSearch";
import NavMenu, { ITab } from "./NavMenuNew";
import Page from "./Page";
import ProfileMenuIcon from "./ProfileMenuIcon";
import { Routes as AppRoutes } from "./Routes";
import SamplerButton from "./SamplerButton";
import EvalReview from "components/Agents/EvalReview/EvalReview";
import SignalsReportStore from "components/Signals/Store/SignalsReportStore";
import { AgentDrawer } from "components/Agent/AgentDrawer";

declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

const WhiteAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.white.main,
    boxShadow:
        "0px 4px 1px -1px rgba(0,0,0,0.02), 0px 1px 1px 0px rgba(0,0,0,0.02), 0px 2px 6px 0px rgba(0,0,0,0.02)",
    height: 72,
}));

const styles = (theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.default,
            position: "relative",
        },
        appBar: {
            backgroundColor: "#FFFFFF !important",
            zIndex: theme.zIndex.drawer + 1,
            height: 72,
        },
        profileButton: {
            marginRight: "0.5rem",
        },
        logo: {
            height: 30,
            cursor: "pointer",
        },
        rightToolbar: {
            marginLeft: "auto",
        },
        tenantIcon: {
            height: 30,
            width: 30,
            borderRadius: "100%",
            backgroundColor: hexToRGB(theme.palette.secondary.main, 0.1),
            color: theme.palette.blue.main,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
        },
        toolbar: theme.mixins.toolbar as CSSProperties, //fix incompatibility with React.CSSProperties
    });

function makeAppTabs(authStore: AuthStore): ITab[] {
    let agentsRoute = AppRoutes.AGENT_COACHING;
    if (
        authStore.isLoggedInUserAgent() &&
        authStore.orgStore.selectedOrganization &&
        authStore.permStore.agent
    )
        agentsRoute = AppRoutes.makeAgentDashboardRoute(
            authStore.orgStore.selectedOrganization.id,
            authStore.permStore.agent.id,
            authStore.permStore.agent.fullName,
        );

    return [
        {
            tabLabel: "Dashboard",
            route: "",
            domain: AppDomains.MyDashboard,
            newDomainName: "My Dashboard",
        },
        {
            tabLabel: "My Evaluations",
            route: "/myevaluations",
            domain: AppDomains.MyEvaluations,
            newDomainName: "My Evaluations",
        },
        {
            tabLabel: "Montage Library",
            route: "/montagelibrary",
            domain: AppDomains.MontageLibrary,
            newDomainName: "Montage Library",
        },
        {
            tabLabel: "Interactions",
            route: "/interactions",
            domain: AppDomains.Interactions,
            newDomainName: "Interactions",
        },
        {
            tabLabel: "Conversations",
            route: "/conversations",
            domain: AppDomains.Ultra,
            newDomainName: "Conversations",
        },
        {
            tabLabel: "Agents",
            route: agentsRoute,
            domain: AppDomains.Agents,
            newDomainName: "Agent",
        },
        {
            tabLabel: "Component Tests",
            route: "/componentsview",
            domain: AppDomains.Ultra,
        },
        {
            tabLabel: "Workflows",
            route: AppRoutes.WORKFLOWS,
            domain: AppDomains.Workflows,
            newDomainName: "Workflows",
        },
        {
            tabLabel: (
                <Stack direction={"row"} display={"flex"} alignItems={"center"}>
                    Signals
                </Stack>
            ),
            route: AppRoutes.Signals,
            domain: AppDomains.Signals,
            newDomainName: "Signals",
        },
        {
            tabLabel: "Classifiers",
            route: "/classifiers",
            domain: AppDomains.Classifiers,
            newDomainName: "Classifiers",
        },
    ];
}

const App = observer(() => {
    const navigate = useNavigate();
    const location = useLocation();
    const authStore = useStore(AuthStore);
    const drawerStore = useStore(LayoutDrawerStore);
    const messageStore = useStore(MessageStore);
    const classes = useStyles(styles);
    const [activeTab, setActiveTab] = useState<number>(0);
    const signalsStore = useStore(SignalsReportStore);

    //prefix for all routes on this page
    const routePrefix = "/app";

    //Function on tab change
    const updateTab = (
        _event: any,
        index: number,
        shouldNav: boolean = true,
    ) => {
        setActiveTab(index);
        if (shouldNav) navigate(routePrefix + tabs[index].route!);
    };

    //Handle special navigation scenarios
    useEffect(() => {
        //If the user isn't authenticated, force them back to login url
        if (!authStore.permStore || !authStore._isAuthenticated) {
            navigate(authStore.getLoginUrl());
            return;
        }

        // const tempLocal = local.getLocalStore();
        // tempLocal.getItem<string | null>("selectedOrgID").then((val) => {
        //     // maa note
        //     // orgs can be disabled and we no longer load disabled orgs
        //     // if the org cannot be found then stick to the users current default
        //     if (val) {
        //         const orgRec = authStore.orgStore.organizations?.find(
        //             (org) => org.id === val,
        //         )! as Organization;
        //         if (orgRec) {
        //             authStore.orgStore?.setGlobalOrganization(orgRec);

        //             authStore.orgStore.onActiveOrgIdChange(val);
        //         }
        //     } else {
        //         tempLocal.removeItem("selectedOrgID");
        //     }
        // });

        //If the user is on the dashboard tab, and they have access to it, let them be
        if (
            location.pathname === routePrefix &&
            tabs.some((tab) => tab.domain === AppDomains.MyDashboard) &&
            activeTab === 0
        ) {
            return;
        }

        const subroutes = location.pathname.split("/");
        const targetSubroute = subroutes[2];
        const currentTabIndex = tabs.findIndex((tab) => {
            const tabRouteParts = tab.route.substring(1).split("/");
            return tabRouteParts[0] === targetSubroute;
        });

        //If the route the user is on isn't in their array of tabs, navigate them to the first tab (dashboard or agent dash).
        if (currentTabIndex === -1) {
            updateTab(undefined, 0);
            return;
        }

        //Otherwise, just make sure the active tab reflects the route the user is on.
        updateTab(undefined, currentTabIndex, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    //List of tabs, filtered accordingly by authorization
    const tabs: ITab[] = useMemo(() => {
        return makeAppTabs(authStore).filter((tab) => {
            //Non-Ultra Agent Users only have access to agent coaching tab
            if (authStore.isLoggedInUserAgent() && !authStore.isUserUltra()) {
                return tab.route.startsWith(AppRoutes.AGENT_COACHING);
            }
            //Non-Agent users always have acess to My Dashboard tab, Ultra users always have access to all tabs
            else if (
                tab.domain === AppDomains.MyDashboard ||
                authStore.isUserUltra()
            ) {
                return true;
            }
            //Non-Agent Non-Ultra users V2 Permissions check for all tabs besides dashboard
            else {
                return (
                    tab.newDomainName &&
                    authStore.canUserView(tab.newDomainName)
                );
            }
        });
    }, [authStore]);

    const hideEvalSearch =
        authStore.isLoggedInUserAgent() && !authStore.isUserUltra();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { elementHeight: appBarHeight, theRef } =
        useResizeObserver<HTMLElement>({
            name: "MainAppNavToolbar",
            pixelDelta: 1,
            debounceWait: 50,
        });

    const getUserInfo = async () => {
        const user = await authStore.getUserObject();
        return user;
    };

    function checkHost() {
        const host = window.location.host;
        return (
            host.includes("acxstaging.authcx.com") ||
            host.includes("localhost:5001")
        );
    }
    const isHostMatch = checkHost();

    if (isHostMatch) {
        const HEAP_ID = "2704216519";
        AnalyticsHeap.initialize(HEAP_ID);

        if (window.heap.identity === null) {
            getUserInfo()
                .then((user) => {
                    const userEmailDomain = user?.profile?.email?.split("@")[1];
                    const userProperties = {
                        organizationId: user?.profile.OrgId,
                        roleName: user?.profile.role,
                        emailDomain: userEmailDomain,
                        isUltraUser:
                            user?.profile.AuthcxRole === "Ultra" ? true : false,
                    };
                    AnalyticsHeap.event.identify(user?.profile.uId);
                    AnalyticsHeap.event.addUserProperties(userProperties);
                })
                .catch((error) => {
                    console.error("Error fetching user info:", error);
                });
        }
    }

    return (
        <>
            <CssBaseline />
            <AcxSnackBar
                open={messageStore.showMessage}
                onClose={messageStore.showNextMessage}
                message={messageStore.currentMessage?.text || ""}
                severity={messageStore.currentMessage?.severity}
            />
            <WhiteAppBar
                ref={theRef}
                style={{
                    boxShadow:
                        "0px 4px 1px -1px rgba(0,0,0,0.02), 0px 1px 1px 0px rgba(0,0,0,0.02), 0px 2px 6px 0px rgba(0,0,0,0.02)",
                    display: signalsStore.isPresentationMode
                        ? "none"
                        : "inherit",
                }}
            >
                <Toolbar style={{ height: "100%" }}>
                    <Grid container wrap={"nowrap"} alignItems={"center"}>
                        <Grid item xs={1}>
                            <img
                                className={classes.logo}
                                alt=""
                                src={logo}
                                style={{ marginRight: "20px" }}
                                onClick={() => navigate("/")}
                            ></img>
                        </Grid>
                        <Grid
                            item
                            xs={hideEvalSearch ? 9 : 8}
                            overflow={"hidden"}
                        >
                            <NavMenu
                                onChange={updateTab}
                                selected={activeTab}
                                tabs={tabs}
                            />
                        </Grid>
                        <Grid
                            container
                            item
                            xs={hideEvalSearch ? 2 : 3}
                            justifyContent={"flex-end"}
                            wrap={"nowrap"}
                        >
                            {!hideEvalSearch && (
                                <Grid item style={{ alignSelf: "center" }}>
                                    <EvaluationSearch />
                                </Grid>
                            )}

                            <Grid item style={{ alignSelf: "center" }}>
                                <AgentChatMenuIcon />
                            </Grid>

                            <Grid item style={{ alignSelf: "flex-start" }}>
                                <SamplerButton
                                    showIcon={authStore.canUserViewAdminApp()}
                                />
                            </Grid>

                            <Grid item style={{ alignSelf: "center" }}>
                                <NotificationMenuIcon
                                    drawerOffsetPx={appBarHeight}
                                />
                            </Grid>

                            <Grid item style={{ alignSelf: "center" }}>
                                <ProfileMenuIcon
                                    currLocation={"mainApp"}
                                    organizationName={
                                        authStore.orgStore.selectedOrganization
                                            ?.name ?? ""
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </WhiteAppBar>
            <div className={classes.toolbar} />
            <div>
                <AgentDrawer />
                <AcxDrawer
                    customRootStyles={drawerStore.customRootStyles}
                    anchor={drawerStore.anchor}
                    open={drawerStore.isOpen}
                    collapsible={drawerStore.collapseSize}
                    variant={drawerStore.variant}
                    offsetPx={drawerStore.offsetPx}
                    offsetPosition={drawerStore.offsetPosition}
                    transitionDuration={drawerStore.transitionDuration}
                    onDrawerClose={() => drawerStore.setOpen(false)}
                    drawerStore={drawerStore}
                    onDrawerOpen={() => drawerStore.setOpen(true)}
                    content={drawerStore.contentInjector?.()}
                />
            </div>
            <Routes>
                <Route
                    path="myevaluations"
                    element={
                        <Page title="My Evaluations | Authenticx">
                            <AnalystDashboard />
                        </Page>
                    }
                ></Route>
                <Route
                    path="montagelibrary"
                    element={
                        <Page title="Montage Library | Authenticx">
                            <MontageLibrary />
                        </Page>
                    }
                ></Route>
                <Route
                    path="initiativeview"
                    element={
                        <Page title="Initiative View | Authenticx">
                            <InitiativeView />
                        </Page>
                    }
                ></Route>
                <Route
                    path="componentsview"
                    element={
                        <Page title="Test Components | Authenticx">
                            <ComponentTestView />
                        </Page>
                    }
                ></Route>
                <Route path={AppRoutes.EVALUATION}></Route>
                <Route
                    path="nlp/*"
                    element={
                        <Page title="Analyticx | Authenticx">
                            <NlpLayout />
                        </Page>
                    }
                ></Route>
                <Route
                    path="classifiers/*"
                    element={
                        <Page title="Classifiers | Authenticx">
                            <ClassifiersLayout />
                        </Page>
                    }
                ></Route>
                <Route
                    path="interactions/*"
                    element={<ManagerInteractions />}
                ></Route>
                <Route
                    path={"conversations/*"}
                    element={
                        <Page title="Conversations | Authenticx">
                            <Conversations />
                        </Page>
                    }
                ></Route>
                <Route
                    path={"agentcoaching"}
                    element={
                        <Page title="Agents | Authenticx">
                            <AgentCoaching />
                        </Page>
                    }
                ></Route>
                <Route
                    path="agentcoaching/evaluationreview"
                    element={
                        <Page title="Evaluation Review | Authenticx">
                            <EvalReview />
                        </Page>
                    }
                ></Route>
                <Route
                    path={"agentcoaching/dashboard/:orgId/:agentId/:agentName"}
                    element={<AgentDashboard />}
                ></Route>
                <Route path={"workflows/*"} element={<Workflows />}></Route>
                <Route
                    path={"reports/*"}
                    element={
                        <Page title="Reports | Authenticx">
                            <Reports />
                        </Page>
                    }
                ></Route>

                <Route
                    path={"reportsRv2/*"}
                    element={
                        <Page title="ReportsRv2 | Authenticx">
                            <ReportsRv2 />
                        </Page>
                    }
                />
                <Route
                    path={"signals/*"}
                    element={
                        <Page title="Signals | Authenticx">
                            <SignalsLayout />
                        </Page>
                    }
                />
                <Route
                    path="/app/classifiertranscription/:fileName?"
                    element={<ClassifierTranscription />}
                />
                <Route
                    path="/"
                    element={
                        <Page title="Dashboard | Authenticx">
                            <LandingDashboard />
                        </Page>
                    }
                ></Route>
            </Routes>
        </>
    );
});

export default function Layout() {
    // console.log("layout component is executing");
    return (
        <StylesProvider generateClassName={generateClassName}>
            <ThemeProviderV5 theme={theme}>
                <App />
            </ThemeProviderV5>
        </StylesProvider>
    );
}
