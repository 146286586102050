import { GridValueFormatterParams } from "@mui/x-data-grid-pro";
import React from "react";
import AcxProgress from "./AcxProgress";

export const ProgressFormatter = (obj: {
    numeratorField: string;
    denominatorField: string;
}) => {
    const fn = (params: GridValueFormatterParams) => {
        const fields = obj;
        const numerator = parseInt(params.row[fields.numeratorField]);
        const denominator = parseInt(params.row[fields.denominatorField]);

        const percentComplete =
            denominator !== 0 && !Number.isNaN(denominator)
                ? (numerator / denominator) * 100
                : 0;

        const label = `${numerator} out of ${denominator}.`;

        const res = (
            <AcxProgress
                key={params.id}
                percentComplete={percentComplete.toString()}
                backgroundColor={"black"}
                bottomLabel={label}
            />
        );

        return res;
    };

    return fn;
};
