import { Typography } from "@material-ui/core";
import { Grid } from "@mui/material";
import { styled, useTheme } from "@mui/styles";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxButton from "components/UI/AcxButton";
import AcxDeleteDialog from "components/UI/AcxDeleteDialog";
import ErrorDialog from "components/UI/AcxErrorDialog";
import AcxImageFileDrop from "components/UI/AcxImageFileDrop";
import AcxImageTile from "components/UI/AcxImageTile";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import VerticalDrawerContentTemplate from "components/UI/Drawer/VerticalDrawerContentTemplate";
import { observer } from "mobx-react";
import React, {
    CSSProperties,
    forwardRef,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { SignalsService } from "services/SignalsService";
import { useStore } from "utils/useStore";

type Prop = {
    dashboardId: string;
    currentLogoName?: string;
    updateDashboard: (logoName?: string) => void;
    closeDrawer: () => void;
};

const CustomizeDashboard: React.FC<Prop> = observer((props: Prop) => {
    const theme = useTheme();
    const messageStore = useStore(MessageStore);
    const [selectedLogoName, setSelectedLogName] = useState<string | undefined>(
        props.currentLogoName,
    );
    const [editsMade, setEditsMade] = useState(false);
    const [loading, setLoading] = useState(true);
    const [orgLogos, setOrgLogos] = useState<
        { blobSasUrl: string; fileName: string }[]
    >([]);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [errorDetails, setErrorDetails] = useState<{
        errorTitle: string;
        errorMessage: string;
    }>({ errorTitle: "", errorMessage: "" });
    const [stagedDeleteLogo, setStagedDeleteLogo] = useState<null | string>(
        null,
    );
    const [deleteLogoLoading, setDeleteLogoLoading] = useState(false);

    const containerRef = useRef<HTMLDivElement>(null);
    const scrollPositionRef = useRef(0);

    const signalsService: SignalsService = new SignalsService();

    async function getLogos() {
        setOrgLogos(await signalsService.getLogos());
        setLoading(false);
    }

    useEffect(() => {
        getLogos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    interface ContainerProps {
        style?: CSSProperties;
        children?: React.ReactNode;
    }

    const Container = forwardRef<HTMLDivElement, ContainerProps>(
        (props, ref) => {
            const theme = useTheme();
            const containerStyle: CSSProperties = {
                backgroundColor: theme.palette.gray[50],
                height: "375px",
                width: "100%",
                display: "grid",
                flexDirection: "column",
                padding: "10px",
                overflowY: "scroll",
                overflowX: "hidden",
                borderRadius: "4px",
                border: `1px solid ${theme.palette.gray[300]}`,
                gridTemplateColumns: "repeat(2, minmax(0, 226px))",
                gridAutoRows: "163px",
                columnGap: "8px",
                rowGap: "8px",
                ...props.style,
            };

            return (
                <div ref={ref} style={containerStyle}>
                    {props.children}
                </div>
            );
        },
    );

    Container.displayName = "Container";

    const SingleDropContainer = styled("div")({
        height: "150px",
        width: "100%",
        display: "grid",
        padding: "10px",
        gridTemplateColumns: "1fr",
        gridAutoRows: "163px",
    });

    // Ensure Scroll Position is maintained on renders
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const container = containerRef.current;

        const handleScroll = () => {
            if (container) {
                scrollPositionRef.current = container.scrollTop;
            }
        };

        if (container) {
            container.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener("scroll", handleScroll);
                if (containerRef.current) {
                    containerRef.current.scrollTop = scrollPositionRef.current;
                }
            }
        };
    }, [orgLogos, loading, selectedLogoName]);

    async function saveLogo(file: File) {
        const newLogo = await signalsService.saveLogo(file, props.dashboardId);
        let orgLogosCopy = [...orgLogos];
        orgLogosCopy.unshift({ blobSasUrl: newLogo, fileName: file.name });
        setOrgLogos(orgLogosCopy);
        selectLogo(file.name);
    }

    const selectLogo = useCallback(
        (logoClicked: string) => {
            if (props.currentLogoName !== logoClicked) {
                setEditsMade(true);
            }
            setSelectedLogName(logoClicked);
        },
        [props.currentLogoName],
    );

    const deleteLogo = useCallback(
        async (logoClicked: string) => {
            setDeleteLogoLoading(true);
            if (logoClicked === selectedLogoName) {
                setSelectedLogName(undefined);
            }
            try {
                await signalsService.deleteLogo(logoClicked, props.dashboardId);
            } catch (e) {
                messageStore.logError("Error deleting logo");
            } finally {
                setStagedDeleteLogo(null);
                getLogos();
                props.updateDashboard();
                setEditsMade(false);
                setDeleteLogoLoading(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.dashboardId],
    );

    const handleFileUploadError = (
        errorTitle: string,
        errorMessage: string,
    ) => {
        setErrorDetails({ errorTitle, errorMessage });
        setErrorDialogOpen(true);
    };

    function getContent() {
        if (containerRef.current != null) {
            containerRef.current.scrollTop = scrollPositionRef.current;
        }

        if (loading) {
            return <AcxLoadingIndicator size={75} alternate="PuffLoader" />;
        }

        return (
            <div
                style={{
                    height: "calc(100% - 2rem)",
                    paddingLeft: "8px",
                    paddingRight: "16px",
                }}
            >
                <Typography
                    style={{
                        color: theme.palette.neutral.main,
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "16px",
                        fontWeight: 600,
                        lineHeight: "24px",
                    }}
                >
                    {"Add Logo"}
                </Typography>
                <Typography
                    style={{
                        color: "#3F3F46",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "12px",
                        fontWeight: 400,
                        lineHeight: "16px",
                        marginBottom: "24px",
                    }}
                >
                    {"Personalize your dashboard by adding a company logo."}
                </Typography>
                {!!orgLogos.length ? (
                    <Container ref={containerRef}>
                        <AcxImageFileDrop
                            uploadHandler={saveLogo}
                            onError={handleFileUploadError}
                        />
                        {orgLogos.map((orgLogo) => {
                            const isHighlighted =
                                selectedLogoName === orgLogo.fileName;

                            return (
                                <AcxImageTile
                                    key={orgLogo.fileName}
                                    imageUri={orgLogo.blobSasUrl}
                                    imageName={orgLogo.fileName}
                                    highlighted={isHighlighted}
                                    onClickHandler={selectLogo}
                                    onDeleteHandler={(logo) => {
                                        setStagedDeleteLogo(logo);
                                    }}
                                />
                            );
                        })}
                    </Container>
                ) : (
                    <SingleDropContainer>
                        <AcxImageFileDrop
                            uploadHandler={saveLogo}
                            onError={handleFileUploadError}
                        />
                    </SingleDropContainer>
                )}
                {editsMade && (
                    <Grid
                        container
                        item
                        sx={{
                            justifyContent: "flex-end",
                            marginTop: "16px",
                        }}
                        columnSpacing={1}
                    >
                        <Grid item>
                            <AcxButton
                                leftRightSpacing={0}
                                fullWidth={false}
                                color="secondary"
                                variant="outlined"
                                onClick={() => {
                                    setEditsMade(false);
                                    setSelectedLogName(undefined);
                                    props.closeDrawer();
                                }}
                                sx={(theme) => ({
                                    color: theme.palette.black.main,
                                    border: `1px solid ${theme.palette.gray[200]}`,
                                    "&:hover": {
                                        color: theme.palette.black.main,
                                        border: `1px solid ${theme.palette.black.main}`,
                                        backgroundColor:
                                            theme.palette.white.main,
                                        opacity: "75%",
                                    },
                                })}
                            >
                                Cancel
                            </AcxButton>
                        </Grid>
                        <Grid item>
                            <AcxButton
                                leftRightSpacing={0}
                                fullWidth={false}
                                color="secondary"
                                onClick={() => {
                                    props.updateDashboard(selectedLogoName);
                                    setEditsMade(false);
                                    props.closeDrawer();
                                }}
                            >
                                Apply
                            </AcxButton>
                        </Grid>
                    </Grid>
                )}

                <ErrorDialog
                    isOpen={errorDialogOpen}
                    onConfirm={() => setErrorDialogOpen(false)}
                    onClose={() => setErrorDialogOpen(false)}
                    title={errorDetails.errorTitle}
                    subTitle={errorDetails.errorMessage}
                />
                <AcxDeleteDialog
                    isOpen={!!stagedDeleteLogo}
                    onCancel={() => {
                        setStagedDeleteLogo(null);
                    }}
                    onDelete={() => {
                        if (stagedDeleteLogo) {
                            deleteLogo(stagedDeleteLogo);
                        }
                    }}
                    text={{
                        title: "Are you sure?",
                        content:
                            "Deleting this image is immediate and permanent. Are you sure you want to delete?",
                        confirm: "Delete Image",
                    }}
                    loading={deleteLogoLoading}
                />
            </div>
        );
    }

    return (
        <Grid container item xs={12} style={{ height: "100%" }}>
            <VerticalDrawerContentTemplate
                title={"Customize Dashboard"}
                // subTitle={this.selectedUserName}
                content={getContent()}
                onClose={() => {
                    setSelectedLogName(undefined);
                }}
            />
        </Grid>
    );
});

export default CustomizeDashboard;
