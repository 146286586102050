import { Switch, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { IApplicationFilterComponent } from "../Filters/IApplicationFilterComponent";

export const FavoriteConversationsFilter = observer(function (
    props: IApplicationFilterComponent,
) {

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}
        >
            <Switch
                id="signals-filters-favorite-conversations"
                checked={props.store.favoriteConversations}
                onChange={() => props.store.toggleFavoriteConversationsFilter()}
                sx={{ minWidth: "40px", marginLeft: 0 }}
            />
            <Typography>Show favorite conversations only</Typography>
        </div>
    );
});
