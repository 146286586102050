import {
    Box,
    Grid,
    IconButton,
    styled,
    Theme,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MoreVert } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import theme from "Theme/AppTheme";
import clsx from "clsx";
import ConversationsStore from "components/Conversations/Stores/ConversationsStore";
import {
    EvalStore,
    createAudioClipTask,
} from "components/Evaluation/Stores/EvalStore";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxMicroPlayer from "components/UI/Audio/AcxMicroPlayer";
import AcxMenu, {
    AcxMenuItemProps,
    StyledMenuLabel,
} from "components/UI/Menu/AcxMenu";
import AcxSelectMulti from "components/UI/Select/BaseSelectComponents/AcxSelectMulti";
import { observer } from "mobx-react";
import SoundClip from "models/SoundClip";
import React, { FunctionComponent, useRef, useState } from "react";
import { UpdateSoundClipRequest } from "services/SoundClipService";
import { AsyncTaskStatus } from "stores/BaseStore";
import { useStore } from "utils/useStore";
import { AuthStore } from "stores/AuthStore";
import AcxButton from "components/UI/AcxButton";
import { AgentLogo } from "components/Agent/Branding/AgentLogo";
import AgentChatStore from "components/Agent/AgentChatStore";
import { InteractionType } from "models/InteractionType";

const AgentToggleButton = styled(AcxButton)(() => ({
    background:
        "linear-gradient(131.1deg, #32D1B7 3.43%, #349BC7 70.89%, #3664D6 100.5%)",
    padding: "4px, 8px, 4px, 6px",
}));

const useConversationClipViewStyles = makeStyles((theme: Theme) => ({
    container: {
        borderBottom: `1px solid ${theme.palette.lightgray.main}`,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playerContainer: { maxWidth: "60px" },
    deleteButton: {
        color: theme.palette.red.main,
        fontFamily: "Inter",
        fontSize: "13px",
        fontWeight: "bold",
        letterSpacing: 0,
        lineHeight: "20px",
        textTransform: "none",
    },
    bottomSpacing: {
        marginBottom: theme.spacing(2),
    },
    controlButton: {
        height: "32px",
        width: "32px",
        color: theme.palette.blue.main,
    },
    bodyContainer: { maxWidth: "100%", flexGrow: 1 },
    padding1: {
        padding: theme.spacing(1),
    },
    clipTitle: {
        color: theme.palette.blue.main,
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        paddingLeft: theme.spacing(1),
    },
    menu: {
        borderRadius: "4px",
        border: "1px solid #E4E4E7",
        background: "#FFF",
        height: "36px",
        width: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    },
}));

interface IConversationClipViewProps {
    index: number;
    title?: string;
    soundClip?: SoundClip;
    isDisabled?: boolean;
    onDeleteButton: () => void;
    montageIds?: string[] | null;
    mediaUrl?: string;
}

const ConversationClipView: FunctionComponent<IConversationClipViewProps> =
    observer(
        ({
            index,
            title,
            soundClip,
            isDisabled,
            onDeleteButton,
            montageIds,
            mediaUrl,
        }) => {
            const classes = useConversationClipViewStyles();
            const store = useStore(ConversationsStore);
            const evalStore = useStore(EvalStore);
            const messageStore = useStore(MessageStore);
            const authStore = useStore(AuthStore);
            const agentChatStore = useStore(AgentChatStore);

            const isUsedInMontage = !!montageIds && montageIds.length !== 0;
            const disableDelete =
                isDisabled || isUsedInMontage || !soundClip?.filePath;
            const showPlayButton =
                !!soundClip?.filePath &&
                store.selectedConversation?.mediaType === 0;

            const menuRef = useRef(null);

            const [editingClipName, setEditingClipName] = useState(false);
            const [isHovered, setIsHovered] = useState(false);
            const [clipName, setClipName] = useState(title);
            const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(
                null,
            );

            const [note, setNote] = useState(soundClip?.note ?? "");
            const [editingNote, setEditingNote] = useState(false);

            const handleClipNameChange = (event) => {
                setClipName(event.target.value);
            };

            const handleNoteChange = (event) => {
                setNote(event.target.value);
            };

            const handleMoreMenuClose = () => {
                setAnchorEl(null);
            };

            const updateClipProperty = async (propertyName: string) => {
                const result = await evalStore.updateTextClip(
                    soundClip as UpdateSoundClipRequest,
                );

                if (result === AsyncTaskStatus.Error)
                    return messageStore.logError(
                        `Failed to save clip ${propertyName}. Please try again.`,
                    );

                messageStore.logInfo(
                    `Clip ${propertyName} saved successfully.`,
                );
            };

            const menuItems: AcxMenuItemProps[] = [
                {
                    id: "conversation-clip-menu-edit",
                    label: <StyledMenuLabel>Edit</StyledMenuLabel>,
                    props: {
                        onClick: () => {
                            if (!soundClip || !soundClip.segmentName) return;
                            store.soundClipEditorStore.selectAndEditSoundClip(
                                soundClip,
                            );

                            setAnchorEl(null);
                        },
                    },
                    hidden: store.selectedConversation?.isChat,
                },
                {
                    id: "conversation-clip-menu-add-a-note",
                    label: <StyledMenuLabel>{"Add a note"}</StyledMenuLabel>,
                    props: {
                        onClick: () => {
                            setEditingNote(true);
                            setAnchorEl(null);
                        },
                    },
                    hidden: !!note || editingNote,
                },
                {
                    id: "conversation-clip-menu-delete",
                    label: <StyledMenuLabel>Delete</StyledMenuLabel>,
                    props: {
                        onClick: () => {
                            onDeleteButton();
                            setAnchorEl(null);
                        },
                    },
                    hidden: disableDelete,
                },
            ];

            return (
                <Grid container item xs={12} className={classes.container}>
                    <Grid
                        container
                        item
                        xs={1}
                        justifyContent="center"
                        alignItems="flex-start"
                        className={classes.playerContainer}
                    >
                        {showPlayButton && (
                            <AcxMicroPlayer
                                controlButtonClass={classes.controlButton}
                                isLoading={store.getTaskLoading(
                                    `${createAudioClipTask}-${soundClip?.id}`,
                                )}
                                getFunction={() => {
                                    if (
                                        soundClip?.segmentChunks &&
                                        soundClip.segmentChunks.length > 0
                                    ) {
                                        return Promise.resolve(
                                            soundClip.segmentChunks[0],
                                        );
                                    }
                                    return store.downloadClipFromBlob(
                                        soundClip?.filePath,
                                    );
                                }}
                                mediaUrl={mediaUrl}
                            />
                        )}
                    </Grid>
                    <Grid
                        container
                        item
                        xs={11}
                        className={classes.bodyContainer}
                    >
                        <Grid
                            container
                            item
                            xs={9}
                            style={{ paddingRight: theme.spacing(2) }}
                            direction="column"
                        >
                            {/* UI States for Editing the Clip Name */}
                            {editingClipName ? (
                                <AcxMainTextField
                                    blurOnEnterPress
                                    value={clipName}
                                    onChange={handleClipNameChange}
                                    onBlur={async () => {
                                        setEditingClipName(false);
                                        setIsHovered(false);

                                        if (soundClip?.segmentName === clipName)
                                            return;

                                        soundClip?.setName(clipName);

                                        updateClipProperty("name");
                                    }}
                                    focus
                                    id="specName"
                                    containerStyle={{ width: "40%" }}
                                    disableTooltip
                                />
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: theme.palette.blue.main,
                                            fontFamily: "Inter",
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            fontWeight: 700,
                                            paddingLeft: theme.spacing(1),
                                        }}
                                        onClick={() => setEditingClipName(true)}
                                        style={{ cursor: "pointer" }}
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}
                                    >
                                        {clipName}
                                    </Typography>
                                    {isHovered && (
                                        <IconButton
                                            style={{
                                                padding: 0,
                                                marginLeft: 2,
                                            }}
                                            size="large"
                                        >
                                            <EditIcon
                                                style={{
                                                    width: "14px",
                                                    height: "14px",
                                                }}
                                            />
                                        </IconButton>
                                    )}
                                </div>
                            )}
                            <Box
                                component="div"
                                className={clsx(
                                    classes.padding1,
                                    "pendo-ignore",
                                )}
                                sx={{
                                    paddingBottom: theme.spacing(1.5),
                                }}
                            >
                                {soundClip?.transcriptionText ??
                                    "No transcription"}
                            </Box>

                            {(!!note || editingNote) && (
                                <AcxMainTextField
                                    blurOnEnterPress
                                    value={note}
                                    containerStyle={{
                                        paddingLeft: theme.spacing(1),
                                        paddingBottom: theme.spacing(1.5),
                                    }}
                                    // This keeps the text field from dissapearing if the user just deletes all the note text at once
                                    onFocus={() => setEditingNote(true)}
                                    onChange={handleNoteChange}
                                    onBlur={async () => {
                                        if (soundClip?.note === note) return;

                                        soundClip?.setNote(note);
                                        updateClipProperty("note");
                                    }}
                                    id={`note-for-clip-${soundClip?.id}`}
                                    placeholderText="Add a note...."
                                    disableTooltip
                                />
                            )}

                            {authStore.canUserEdit("Montage Library") &&
                                authStore.canUserView("View Ava") &&
                                soundClip?.mediaType ===
                                    InteractionType.Audio && (
                                    <AgentToggleButton
                                        onClick={() =>
                                            agentChatStore.showAgentBuildMontageForClip(
                                                soundClip!,
                                            )
                                        }
                                        leftRightSpacing={0}
                                        fullWidth={false}
                                        sx={{
                                            height: "24px",
                                            gap: "8px",
                                            paddingLeft: "6px",
                                            paddingRight: "8px",
                                            marginLeft: theme.spacing(1),
                                        }}
                                    >
                                        <AgentLogo color="white" />
                                        Create a montage with similar clips
                                    </AgentToggleButton>
                                )}
                            {/* <AcxMainTextField
                                id={"transcribed-text-field"}
                                multiLine
                                rows={4}
                                rowsMax={8}
                                helperText={"Transcription Failed"}
                                error={
                                    Boolean(
                                        store.getTaskError(
                                            LoadAudioTranscriptionTask,
                                        )?.message,
                                    ) && !Boolean(soundClip?.transcriptionText)
                                }
                                placeholderText={
                                    !Boolean(soundClip?.transcriptionText) &&
                                    store.getTaskLoading(
                                        LoadAudioTranscriptionTask,
                                    )
                                        ? "Loading Transcription..."
                                        : undefined
                                }
                                onChange={(evt) =>
                                    soundClip?.setTranscriptionText(
                                        evt.target.value,
                                    )
                                }
                                labelText={"Transcribed Clip"}
                                disabled={isDisabled}
                                value={soundClip?.transcriptionText}
                            /> */}
                        </Grid>
                        <Grid
                            container
                            item
                            xs={3}
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            <Grid
                                item
                                xs={10}
                                className={classes.bottomSpacing}
                            >
                                {store.groupClipTags.length > 0 && (
                                    <AcxSelectMulti
                                        isClearable={true}
                                        containerHeight={"auto"}
                                        inputLabel={"Add Tag(s)"}
                                        id={`clip-tag-selection-${
                                            soundClip?.id ?? ""
                                        }-${index}`}
                                        fullWidth
                                        options={store.groupClipTags as any[]}
                                        colorField={"sentimentColor"}
                                        customStyle={{
                                            menuPortal: (provided, state) => ({
                                                ...provided,
                                                zIndex: 9999,
                                            }),
                                        }}
                                        menuPlacement={"auto"}
                                        menuPortalTarget={document.body}
                                        onChange={soundClip?.setClipTags}
                                        defaultValue={soundClip?.clipTags ?? []}
                                        valueField="id"
                                        labelField="value"
                                        groupCollapse={true}
                                        isDisabled={isDisabled}
                                    />
                                )}
                            </Grid>

                            <Grid item xs={2}>
                                <div
                                    ref={menuRef}
                                    className={classes.menu}
                                    onClick={() => setAnchorEl(menuRef.current)}
                                >
                                    <MoreVert />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>

                    <AcxMenu
                        menuItems={menuItems}
                        anchorElement={anchorEl}
                        onMenuClose={handleMoreMenuClose}
                        transformOrigin={{
                            vertical: -3, // move menu down 3px from button
                            horizontal: "right",
                        }}
                    />
                </Grid>
            );
        },
    );

export default ConversationClipView;
