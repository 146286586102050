import { Grid, Paper, styled, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ConversationsStore, {
    loadConversation,
} from "components/Conversations/Stores/ConversationsStore";
import SoundClipEditorV2 from "components/SoundClipEditor/SoundClipEditorV2";
import AcxButton from "components/UI/AcxButton";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import SuccessDialog from "components/UI/AcxSuccessDialog";
import { InputSearchTranscription } from "components/UI/AcxTranscription";
import { InputSearchChat } from "components/UI/Chat/Views/InputSearchChat";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { customSecondaryButtonStyle } from "utils/Style";
import { useStore } from "utils/useStore";
import EvaluateConversationDialog from "../EvaluateConversationDialog";
import FocusedConversationTabs from "./FocusedConversationTabs";
import { Routes } from "components/Navigation/Routes";
import { Favorite, FavoriteBorder } from "@mui/icons-material";

const useFocusedConversationStyles = makeStyles((theme: Theme) => ({
    container: {
        width: "100%",
        borderRadius: "8px",
    },
    soundClipContainer: {
        width: "100%",
        marginBottom: "20px",
        borderRadius: theme.spacing(1),
        overflow: "hidden",
    },
    tab: {
        minWidth: "min-content",
        textTransform: "none",
        fontWeight: 600,
        fontSize: "0.85rem",
        paddingRight: "20px",
    },
    marginFix: {
        marginTop: "-25px",
    },
    evaluateConversationDialogTitle: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "20px",
        color: "#3F3F46",
    },
    evaluateConversationDialogHierachy: {
        color: "#3F3F46",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "20px",
    },
    evaluateConversationDialogContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "12px",
        alignSelf: "stretch",
    },
}));

const ButtonText = styled(Typography)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
    color: theme.palette.gray[700],
}));

const FocusedConversation: React.FunctionComponent = observer(() => {
    const store = useStore(ConversationsStore);

    const classes = useFocusedConversationStyles();

    const navigate = useNavigate();

    let { id } = useParams();

    useEffect(() => {
        if (!store.selectedConversationId) {
            store.setSelectedConversationId(id);
        }

        return () => {
            store.setSelectedConversationId(undefined);
        };
    }, [id, store, store.selectedConversationId]);

    useEffect(() => {
        if (!store.handleUnauthorizedError) {
            store.handleUnauthorizedError = (e) => {
                navigate(Routes.HOME, { replace: true });
            };
        }
        return () => {
            store.handleUnauthorizedError = undefined;
        };
    }, [navigate, store]);

    useEffect(() => {
        if (!store || !store.currentClip.filePath) return;
        store.setSoundClipEditorStore();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentClip.filePath]);

    const showSoundClipEditor =
        !store.selectedConversation?.chatTranscription &&
        store.selectedConversation?.mediaUrl &&
        store.selectedConversation?.callDurationMillis !== 0;

    const canSample =
        store.authStore.canUserEdit("Sample Single Conversation") &&
        !store.selectedConversation?.evalType;

    const [sampleDisabled, setSampleDisabled] = useState(false);

    function getFavoriteButton() {
        const conversationId = store.selectedConversation?.conversationId;
        if (!conversationId) return;

        let favorited = false;

        if (!!store.favoritedConversations) {
            favorited = store.favoritedConversations[conversationId] ?? false;
        }

        if (favorited) {
            return (
                <AcxButton
                    id="favorite-conversation"
                    onClick={() => store.removeFavorite(conversationId)}
                    rootStyle={customSecondaryButtonStyle}
                    styles={{ marginRight: "8px"}}
                >
                    <Favorite
                        color="primary"
                        style={{ fontSize: 20, marginRight: "5px" }}
                    />
                    <ButtonText>Favorite</ButtonText>
                </AcxButton>
            );
        } else {
            return (
                <AcxButton
                    id="favorite-conversation"
                    onClick={() => store.addFavorite(conversationId)}
                    rootStyle={customSecondaryButtonStyle}
                    styles={{ marginRight: "8px"}}
                >
                    <FavoriteBorder
                        color="disabled"
                        style={{ fontSize: 20, marginRight: "5px" }}
                    />
                    <ButtonText>Favorite</ButtonText>
                </AcxButton>
            );
        }
    }

    return store.getTaskLoading(loadConversation) ||
        !store.soundClipEditorStore ? (
        <AcxLoadingIndicator alternate="PuffLoader" size={42} />
    ) : (
        <Grid xs={12} item className={classes.marginFix}>
            <Grid container item alignItems="center">
                <Grid item style={{ marginRight: "auto" }}>
                    <Typography variant="h1">Conversation Details</Typography>
                </Grid>
                {store.authStore.orgStore.selectedOrganization?.id &&
                    store.selectedConversation?.conversationId && (
                        <Grid item>{getFavoriteButton()}</Grid>
                    )}
                {store.authStore.orgStore.selectedOrganization?.id &&
                    canSample &&
                    store.selectedConversation?.conversationId && (
                        <Grid item>
                            <AcxButton
                                styles={{ marginLeft: 0, marginRight: "8px" }}
                                buttonDisabled={sampleDisabled}
                                onClick={
                                    store.toggleEvaluateConversationDialogOpen
                                }
                                rootStyle={customSecondaryButtonStyle}
                            >
                                <ButtonText>{`Evaluate this ${
                                    !store.selectedConversation
                                        .callDurationMillis
                                        ? "chat"
                                        : "call"
                                }`}</ButtonText>
                            </AcxButton>
                        </Grid>
                    )}
                <Grid item className="pendo-ignore">
                    {store.selectedConversation?.isChat &&
                    store.selectedConversation?.chatTranscription ? (
                        <InputSearchChat
                            transcriptionWords={
                                store.selectedConversation?.chatTranscription
                            }
                        />
                    ) : (
                        <InputSearchTranscription />
                    )}
                </Grid>
            </Grid>
            {showSoundClipEditor && (
                <Paper className={classes.soundClipContainer} elevation={0}>
                    <SoundClipEditorV2
                        soundClipEditorStore={store.soundClipEditorStore}
                        playerOptions={store.playerOptions}
                        fullControls={true}
                        noSegmentRemoval
                        id={"focused-conversation-audio-interaction-editor"}
                        generateMp3={false}
                        clip={store.currentClip as any}
                        editorHeight={88}
                        enableZoom
                        onClipGenerated={store.onAudioClipCreated}
                        onClipUpdated={store.onAudioClipUpdated}
                        mediaUrl={store.selectedConversation?.mediaUrl}
                        segmentList={store.clipsToSegmentList}
                    />
                </Paper>
            )}
            <FocusedConversationTabs />

            {/* Evaluate Conversation Confirmation Dialog */}
            {store.evaluateConversationDialogOpen && (
                <EvaluateConversationDialog
                    onConfirmCallback={() => {
                        setSampleDisabled(true);
                        store.toggleEvaluationSuccessDialogOpen();
                    }}
                />
            )}

            {/* Success Dialog  */}
            {store.evaluateSuccessDialogOpen && (
                <SuccessDialog
                    isOpen={store.evaluateSuccessDialogOpen}
                    onClose={store.toggleEvaluationSuccessDialogOpen}
                    confirmButtonText="Back to Conversations"
                    onConfirm={store.toggleEvaluationSuccessDialogOpen}
                    title="Conversations Sampling"
                    subTitle="You’ll be notified when sampling is complete. Reporting data for these evaluations will be available tomorrow."
                />
            )}
        </Grid>
    );
});

export default FocusedConversation;
