import {
    Divider,
    Grid,
    IconButton,
    Paper,
    Theme,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import clsx from "clsx";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import { Observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { InteractionType } from "../../../models/InteractionType";
import { useStore } from "../../../utils/useStore";
import { EvalStore } from "../Stores/EvalStore";
import { isTextMediaType } from "./EvalPage";

export interface InteractionMetadataProps {
    drawerPixelOffset: number;
    interactionType?: InteractionType;
}

const useStyles = makeStyles((theme: Theme) => ({
    super: {
        width: "100%",
        boxShadow: "-2px 0 2px -2px #888",
        height: "100%",
        overflowX: "hidden",
        paddingTop: "12px",
        minWidth: "141px",
    },
    title: {
        paddingLeft: "12px",
        paddingRight: "12px",
        paddingBottom: "12px",
        paddingTop: "6px",
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
    },
    interactionMetadata: (props: InteractionMetadataProps) => ({
        overflowX: "hidden",
        margin: "12px",
    }),
    firstRow: {
        padding: "16px 12px 8px 12px",
    },
    lastRow: {
        padding: "8px 12px 32px 12px",
    },
    labelContent: {
        wordBreak: "break-all",
    },
}));

export default function InteractionMetadata(props: InteractionMetadataProps) {
    const classes = useStyles(props);
    const store = useStore(EvalStore);
    return (
        <Observer>
            {() => (
                <Paper square className={clsx(classes.super)}>
                    <div className={classes.title}>
                        <Typography variant="h2" gutterBottom={false}>
                            Interaction Metadata
                        </Typography>
                        <IconButton
                            onClick={store.toggleShowInteractionMetadata}
                            size="large"
                        >
                            <CloseIcon fontSize={"medium"} />
                        </IconButton>
                    </div>
                    <Divider variant={"fullWidth"} />
                    <div
                        className={clsx(classes.interactionMetadata)}
                        style={{
                            height: `calc(100% - ${
                                isTextMediaType(props.interactionType)
                                    ? 0
                                    : props.drawerPixelOffset + 100 // Account for Metadata header
                            }px)`,
                        }}
                    >
                        <Scrollbars autoHide>
                            <Grid container spacing={2}>
                                <Grid item container alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography variant="h2">
                                            Duration
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {store.currentEval?.interaction
                                            ?.audioMetadata?.callDurationMillis
                                            ? moment
                                                  .utc(
                                                      store.currentEval
                                                          ?.interaction
                                                          ?.audioMetadata
                                                          ?.callDurationMillis,
                                                  )
                                                  .format("mm:ss")
                                            : "Unavailable"}
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography variant="h2">
                                            File Name
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={clsx(classes.labelContent)}
                                    >
                                        {store.currentEval?.interaction
                                            ?.audioMetadata?.fileName ||
                                            "Unavailable"}
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography variant="h2">
                                            File Size
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={clsx(classes.labelContent)}
                                    >
                                        {store.currentEval?.interaction
                                            ?.audioMetadata?.fileSizeKiloBytes
                                            ? store.currentEval?.interaction
                                                  ?.audioMetadata
                                                  ?.fileSizeKiloBytes + " KB"
                                            : "Unavailable"}
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography variant="h2">
                                            Interaction Date
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={clsx(classes.labelContent)}
                                    >
                                        {store.currentEval?.interaction?.InteractionDateAsMoment?.format(
                                            "MM/DD/YYYY, h:mm:ss a",
                                        ) || "Unavailable"}
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography variant="h2">
                                            Arrived Date
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={clsx(classes.labelContent)}
                                    >
                                        {moment(
                                            store.currentEval?.interaction
                                                ?.audioMetadata?.arrivedOn,
                                        ).format("MM/DD/YYYY") || "Unavailable"}
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography variant="h2">
                                            Sampled Date
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={clsx(classes.labelContent)}
                                    >
                                        {moment(
                                            store.currentEval?.createdOn,
                                        ).format("MM/DD/YYYY") || "Unavailable"}
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography variant="h2">
                                            Client Call Id
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={clsx(classes.labelContent)}
                                    >
                                        {store.currentEval?.interaction
                                            ?.audioMetadata?.clientCallId ||
                                            "Unavailable"}
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography variant="h2">
                                            Metadata 1
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={clsx(classes.labelContent)}
                                    >
                                        {store.currentEval?.interaction
                                            ?.audioMetadata?.meta1 ||
                                            "Unavailable"}
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography variant="h2">
                                            Metadata 2
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={clsx(classes.labelContent)}
                                    >
                                        {store.currentEval?.interaction
                                            ?.audioMetadata?.meta2 ||
                                            "Unavailable"}
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography variant="h2">
                                            Metadata 3
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={clsx(classes.labelContent)}
                                    >
                                        {store.currentEval?.interaction
                                            ?.audioMetadata?.meta3 ||
                                            "Unavailable"}
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography variant="h2">
                                            Metadata 4
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={clsx(classes.labelContent)}
                                    >
                                        {store.currentEval?.interaction
                                            ?.audioMetadata?.meta4 ||
                                            "Unavailable"}
                                    </Grid>
                                </Grid>
                                <Grid item container alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography variant="h2">
                                            Metadata 5
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={clsx(classes.labelContent)}
                                    >
                                        {store.currentEval?.interaction
                                            ?.audioMetadata?.meta5 ||
                                            "Unavailable"}
                                    </Grid>
                                </Grid>
                                {store.getTaskLoading(
                                    "Get Extended Metadata",
                                ) ? (
                                    <AcxLoadingIndicator
                                        size={24}
                                        alternate="PuffLoader"
                                    />
                                ) : store.extendedMetadataValues ? (
                                    Object.entries(
                                        store.extendedMetadataValues,
                                    ).map((kv_pair) => {
                                        return (
                                            <Grid
                                                item
                                                container
                                                alignItems="center"
                                                xs={12}
                                            >
                                                <Grid item xs={12}>
                                                    <Typography variant="h2">
                                                        {kv_pair[0]}
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={clsx(
                                                        classes.labelContent,
                                                    )}
                                                >
                                                    {!!kv_pair[1] &&
                                                        (kv_pair[1] as any)}
                                                </Grid>
                                            </Grid>
                                        );
                                    })
                                ) : (
                                    <Grid
                                        item
                                        container
                                        alignItems="center"
                                        xs={12}
                                    >
                                        <Grid item xs={12}>
                                            <Typography variant="h2">
                                                Extended Metadata
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            className={clsx(
                                                classes.labelContent,
                                            )}
                                        >
                                            Unavailable
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Scrollbars>
                    </div>
                </Paper>
            )}
        </Observer>
    );
}
