import * as React from "react";
import { SelectHierarchiesAction } from "./SelectHierarchies";
import { ThreadActionType } from "../Models/ThreadAction";
import { SelectTopicsAction } from "./SelectTopics";

export interface ActionProps {
    args: any;
}

export const ActionTypeToComponent: Record<
    ThreadActionType,
    React.FunctionComponent<ActionProps>
> = {
    [ThreadActionType.SelectHierarchy]: SelectHierarchiesAction,
    [ThreadActionType.SelectTopic]: SelectTopicsAction,
};
