export enum ThreadActionType {
    SelectHierarchy = "SelectHierarchy",
    SelectTopic = "SelectTopic",
}

export type ThreadActionsMap = {
    [ThreadActionType.SelectHierarchy]: undefined;
    [ThreadActionType.SelectTopic]: undefined;
    // [ThreadActionType.MakeAChoice]?: MakeAChoiceActionValue;
};

export class ThreadActions {
    private map: Map<string, unknown>;

    constructor() {
        this.map = new Map();
    }

    public static from(map: ThreadActionsMap): ThreadActions {
        const actions = new ThreadActions();
        for (const [key, value] of Object.entries(map))
            actions.set(key as keyof ThreadActionsMap, value);
        return actions;
    }

    public set<T extends keyof ThreadActionsMap>(
        type: T,
        value: ThreadActionsMap[T],
    ) {
        this.map.set(type, value);
    }

    public get<T extends keyof ThreadActionsMap>(type: T): ThreadActionsMap[T] {
        return this.map.get(type) as ThreadActionsMap[T];
    }

    public has<T extends keyof ThreadActionsMap>(type: T) {
        return this.map.has(type);
    }

    public entries() {
        return Array.from(this.map.entries()) as Array<
            [keyof ThreadActionsMap, ThreadActionsMap[keyof ThreadActionsMap]]
        >;
    }
}
