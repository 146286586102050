import React from "react";
import { Grid, Typography } from "@mui/material";
import { MessageAuthor } from "./Models/MessageAuthor";
import { AgentAvatar } from "./Branding/AgentAvatar";
import { UserAvatar } from "./Branding/UserAvatar";
import Message from "./Messages/Message";
import { useStore } from "utils/useStore";
import AgentChatStore from "./AgentChatStore";
import { observer } from "mobx-react";
import { IThreadMessageGroup } from "./Models/ThreadMessageGroup";
import { ActionTypeToComponent } from "./Actions/Actions";
import { Suggestion } from "./Suggestions/Suggestion";
import { useNavigate } from "react-router";

type ThreadMessageGroupProps = {
    isMostRecent: boolean;
    cancelledStreaming: boolean;
} & IThreadMessageGroup;

export const ThreadMessageGroup = observer((props: ThreadMessageGroupProps) => {
    const store = useStore(AgentChatStore);
    const navigate = useNavigate();

    const isUserMessage = props.author === MessageAuthor.User;
    const isAgentMessage = props.author === MessageAuthor.Agent;

    return (
        <Grid
            container
            item
            direction="column"
            flexWrap="nowrap"
            gap={1}
            alignSelf={isUserMessage ? "self-end" : "self-start"}
            width="300px"
        >
            <Grid
                container
                item
                alignItems="flex-end"
                flexWrap="nowrap"
                gap={1}
            >
                {isAgentMessage && (
                    <Grid item>
                        <AgentAvatar />
                    </Grid>
                )}
                <Grid
                    container
                    item
                    direction="column"
                    rowGap={1}
                    flexWrap="nowrap"
                    alignItems={isUserMessage ? "flex-end" : "flex-start"}
                >
                    {props.messages.map((message, index) => (
                        <Message
                            key={message.id}
                            author={props.author}
                            {...message}
                        />
                    ))}
                    {props.isMostRecent && props.transientMessage && (
                        <Message
                            key={props.transientMessage.id}
                            author={props.author}
                            {...props.transientMessage}
                        />
                    )}
                </Grid>
                {isUserMessage && (
                    <Grid item>
                        <UserAvatar />
                    </Grid>
                )}
            </Grid>
            {props.cancelledStreaming && (
                <Grid
                    className={"pendo-ignore"}
                    container
                    item
                    alignItems="flex-end"
                    flexWrap="nowrap"
                    gap={1}
                    direction={"row"}
                >
                    <Grid item width="28px"></Grid>
                    <Grid item>
                        <Typography
                            sx={(theme) => ({
                                color: theme.palette.gray[600],
                                fontSize: "13px",
                                fontStyle: "italic",
                                lineHeight: "20px",
                                paddingLeft: theme.spacing(2),
                            })}
                        >
                            You cancelled the response
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {props.suggestions && props.isMostRecent && (
                <Grid
                    className={"pendo-ignore"}
                    container
                    item
                    alignItems="flex-end"
                    flexWrap="nowrap"
                    gap={1}
                    direction={isAgentMessage ? "row" : "row-reverse"}
                >
                    <Grid item width="28px"></Grid>
                    <Grid
                        container
                        item
                        direction="column"
                        gap={1}
                        flexWrap="nowrap"
                        alignItems={isUserMessage ? "flex-end" : "flex-start"}
                    >
                        {props.suggestions.map((suggestion) => (
                            <Grid item key={suggestion.content}>
                                <Suggestion
                                    onClick={() =>
                                        store
                                            .handleSuggestion(suggestion)
                                            .then(() => {
                                                if (suggestion.navigationURL) {
                                                    navigate(
                                                        suggestion.navigationURL,
                                                    );
                                                }
                                            })
                                    }
                                >
                                    {suggestion.content}
                                </Suggestion>
                            </Grid>
                        ))}
                        {props.actions?.entries().map(([type, args]) => {
                            const Component = ActionTypeToComponent[type];
                            if (!Component) return null;
                            return <Component args={{}} />;
                        })}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
});
