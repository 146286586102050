import { FilterKey } from "stores/ApplicationFilters/ApplicationFilterItemsStore";
import { IApplicationFilterComponent } from "./IApplicationFilterComponent";
import {
    AdverseEventFilter,
    AgentFilter,
    CallDirectionsFilter,
    CallDurationFilter,
    ClassifiersFilter,
    ContactTypeFilter,
    DateRangeFilter,
    DateReferenceFilter,
    DefaultFilter,
    EddyEffectFilter,
    EvaluationTypesFilter,
    ExtendedMetadataInputFilter,
    HierarchiesFilter,
    HipaaComplianceFilter,
    MediaTypesFilter,
    SafetyEventFilter,
    SentimentFilter,
    TopicsFilter,
    WordsAndPhrasesFilter,
    FavoriteConversationsFilter
} from ".";
import { observer } from "mobx-react";
import React from "react";

export const filterKeyToComponent: Record<
    FilterKey,
    React.FunctionComponent<IApplicationFilterComponent> | undefined
> = {
    callDuration: CallDurationFilter,
    minCallDuration: undefined,
    maxCallDuration: undefined,
    startDate: undefined,
    endDate: undefined,
    dateRange: DateRangeFilter,
    dateReferenceOption: DateReferenceFilter,
    dateRangeLabel: undefined,
    evaluationTypes: EvaluationTypesFilter,
    hierarchyIds: HierarchiesFilter,
    agentId: AgentFilter,
    mediaTypes: MediaTypesFilter,
    callDirections: CallDirectionsFilter,
    clientCallId: DefaultFilter,
    meta1: DefaultFilter,
    meta2: DefaultFilter,
    meta3: DefaultFilter,
    meta4: DefaultFilter,
    meta5: DefaultFilter,
    extendedMetadata: ExtendedMetadataInputFilter,
    beginningSentiment: SentimentFilter,
    endingSentiment: SentimentFilter,
    eddyEffectStatus: EddyEffectFilter,
    safetyEvent: SafetyEventFilter,
    hipaaCompliance: HipaaComplianceFilter,
    adverseEvent: AdverseEventFilter,
    topics: TopicsFilter,
    containsTopics: undefined,
    wordsAndPhrasesSearchString: WordsAndPhrasesFilter,
    wordsAndPhrasesItems: undefined,
    wordsAndPhrasesIsInputStandard: undefined,
    rbcFilterItems: ClassifiersFilter,
    contactTypes: ContactTypeFilter,
    favoriteConversations: FavoriteConversationsFilter
};

export const FilterComponent = observer(function (
    props: IApplicationFilterComponent,
) {
    let InnerFilterComponent = filterKeyToComponent[props.filterKey];

    const isExtendedMetadataItem =
        props.filterKey.startsWith("extendedMetadata");
    if (isExtendedMetadataItem)
        InnerFilterComponent = filterKeyToComponent["extendedMetadata"];

    if (!InnerFilterComponent) {
        console.error(
            `Invalid or unsupported filter key: ${props.filterKey}. Nothing rendered.`,
        );
        return null;
    }

    return <InnerFilterComponent {...props} />;
});
