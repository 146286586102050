import { action, computed, makeObservable, observable } from "mobx";
import { uuidv4 } from "utils/helpers";
import { MessageAuthor } from "./MessageAuthor";
import type { ThreadActionsMap } from "./ThreadAction";
import { TemplatedThreadMessage, ThreadMessage, type IThreadMessage } from "./ThreadMessage";
import type {
    ThreadServerSuggestion,
    ThreadSuggestion,
    ThreadSuggestionAction,
} from "./ThreadSuggestion";
import { ThreadActions } from "./ThreadAction";

export interface IThreadMessageGroup {
    readonly id: string;
    author: MessageAuthor;
    messages: IThreadMessage[];
    suggestions?: ThreadSuggestion[];
    actions?: ThreadActions;
    transientMessage?: IThreadMessage;
}

/**
 * Represents a collection of messages sent by an individual author.
 */
export class ThreadMessageGroup implements IThreadMessageGroup {
    public readonly id: string;
    @observable author: MessageAuthor;
    @observable messages: IThreadMessage[];
    @observable suggestions?: ThreadSuggestion[];
    @observable actions: ThreadActions;
    @observable transientMessage?: IThreadMessage;

    constructor(author: MessageAuthor) {
        makeObservable(this);

        this.id = uuidv4();
        this.author = author;
        this.messages = [];
        this.suggestions = [];
        this.actions = new ThreadActions();
    }

    @computed get recentMessage(): IThreadMessage | undefined {
        return this.messages.at(-1);
    }

    public addMessage(message: IThreadMessage): IThreadMessage;
    public addMessage(content: string): IThreadMessage;
    @action
    public addMessage(messageOrContent: string | IThreadMessage): IThreadMessage {
        let message: IThreadMessage;

        if (typeof messageOrContent === "string")
            message = new ThreadMessage(messageOrContent);
        else message = messageOrContent;

        this.messages.push(message);
        return message;
    }

    @action
    public setActions(actions: ThreadActionsMap) {
        this.actions = ThreadActions.from(actions);
    }

    public addSuggestion(suggestion: ThreadSuggestion): ThreadSuggestion;
    public addSuggestion(
        content: string,
        action: ThreadSuggestionAction,
    ): ThreadSuggestion;
    @action
    public addSuggestion(
        suggestionOrContent: string | ThreadSuggestion,
        action?: ThreadSuggestionAction,
    ): ThreadSuggestion {
        let suggestion: ThreadSuggestion;

        if (typeof suggestionOrContent === "string") {
            if (!action) throw new Error("An action must be provided");
            suggestion = { content: suggestionOrContent, action };
        } else suggestion = suggestionOrContent;

        if (!this.suggestions) this.suggestions = [];
        this.suggestions.push(suggestion);
        return suggestion;
    }

    public addServerSuggestion(
        serverSuggestion: ThreadServerSuggestion,
    ): ThreadSuggestion {
        const suggestion: ThreadSuggestion =
            this.compileServerSuggestion(serverSuggestion);
        if (!this.suggestions) this.suggestions = [];
        this.suggestions.push(suggestion);
        return suggestion;
    }

    public addServerSuggestions(
        serverSuggestions: ThreadServerSuggestion[],
    ): ThreadSuggestion[] {
        const suggestions: ThreadSuggestion[] = [];
        for (const serverSuggestion of serverSuggestions)
            suggestions.push(this.addServerSuggestion(serverSuggestion));
        return suggestions;
    }

    private compileServerSuggestion(
        serverSuggestion: ThreadServerSuggestion,
    ): ThreadSuggestion {
        return {
            content: serverSuggestion.content,
            action: this.createActionCallbackFromServerSuggestion(
                serverSuggestion,
            ),
        };
    }

    private createActionCallbackFromServerSuggestion(
        suggestion: ThreadServerSuggestion,
    ): ThreadSuggestionAction {
        if (!suggestion.action)
            return (store, s) =>
                store.streamingSubmitInput({
                    manualInput: s.content,
                    suggestion: true,
                });

        if (suggestion.action.type === "navigate") {
            const data = suggestion.action.data;
            if (
                suggestion.action.domain === "conversations" &&
                Object.hasOwn(data, "conversationIds")
            ) {
                return async (store) => {
                    await store.localStorage.setItem(
                        "agentConversationIds",
                        data["conversationIds"],
                    );
                    window.open(
                        `${window.location.origin}/app/conversations?agent=true`,
                    );
                };
            }
        }

        return () => {
            console.warn(
                "Server suggestion action not implemented yet",
                suggestion,
            );
        };
    }

    @action
    public setSuggestions(suggestions: ThreadSuggestion[]) {
        this.suggestions = suggestions;
    }

    @action
    public clearSuggestions() {
        this.suggestions = [];
    }

    @action
    public setTransientMessage(
        content: string,
        suggestions?: ThreadSuggestion[],
        bindingObject?: unknown,
        buildContent?: (arg: unknown) => string
    ) {
        this.transientMessage = bindingObject && buildContent ? new TemplatedThreadMessage(content, bindingObject, buildContent) : new ThreadMessage(content);
        if (suggestions) this.setSuggestions(suggestions);
    }

    /**
     * If a transient message is set, it is added to the `messages` array and
     * `clearTransientMessage` is called.
     */
    @action
    public commitTransientMessage() {
        var message = this.transientMessage;
        if (!message) return;
        this.addMessage(message);
        this.clearTransientMessage();
        return message;
    }

    @action
    public clearTransientMessage() {
        this.transientMessage = undefined;
    }
}
