import { Grid } from "@mui/material";
import AgentChatStore from "components/Agent/AgentChatStore";
import AcxHierarchySelector from "components/UI/AcxHierarchySelector/AcxHierarchySelector";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import React, { useRef, useState } from "react";
import { AuthStore } from "stores/AuthStore";
import { useStore } from "utils/useStore";
import { Suggestion } from "../Suggestions/Suggestion";
import AcxButton from "components/UI/AcxButton";
import RecursiveTreeStore from "components/UI/AcxRecursiveTree/Stores/AcxRecursiveTreeStore";
import { observer } from "mobx-react";

export const SelectHierarchiesAction = observer(() => {
    const authStore = useStore(AuthStore);
    const store = useStore(AgentChatStore);
    const [dialogOpen, setDialogOpen] = useState(false);
    const treeStoreRef = useRef<RecursiveTreeStore | null>(null);

    return (
        <React.Fragment>
            <AcxDialog
                isOpen={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                }}
                title="Hierarchies"
                dialogContentChildren={
                    <AcxHierarchySelector
                        displayType="baseOnly"
                        orgId={
                            authStore.orgStore.selectedOrganization?.id ?? ""
                        }
                        userId={authStore._user.profile.sub}
                        setHierarchyIds={() => void 0}
                        onSaveUpdateWithHierarchies={() => void 0}
                        hideBottomContent
                        treeStoreGetter={(ts) => (treeStoreRef.current = ts)}
                    />
                }
            >
                <AcxButton
                    leftRightSpacing={0}
                    fullWidth={false}
                    onClick={() => {
                        if (!treeStoreRef.current) return;
                        store.streamingSubmitInput({
                            manualInput:
                                treeStoreRef.current.selectedBranchLabels.join(
                                    ", ",
                                ),
                        });
                    }}
                >
                    Use selected hierarchies
                </AcxButton>
            </AcxDialog>
            <Grid item>
                <Suggestion onClick={() => setDialogOpen((open) => !open)}>
                    Select Hierarchies
                </Suggestion>
            </Grid>
        </React.Fragment>
    );
});
